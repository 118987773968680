import React, {JSX} from "react";
import {Link} from "react-router-dom";
import {FiDelete} from "react-icons/fi";
import {useGetCharts} from "src/api/charts/useGetCharts";
import {useDeleteChart} from "src/api/charts/useDeleteChart";
import {Spinner} from "src/primitives/spinner.jsx";
import {LINKS} from "src/links.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {overrideSystemHandling} from "src/utils/system.js";
import {Chart} from "src/types/chart";


interface ChartListProps {
    tableId: string;
    onSelect?: (chartId: string) => void;
}

export function ChartList({tableId, onSelect}: ChartListProps): JSX.Element {
    const {charts, loading, error} = useGetCharts(tableId);
    const {showLoading, showError, showSuccess} = useShowToast();
    const [deleteChart] = useDeleteChart();


    const doDeleteChart = (chartId: string, e: MouseEvent) => {
        overrideSystemHandling(e);

        showLoading();
        // @ts-ignore
        deleteChart(chartId).then((result) => {
            const {ok, errorCode} = result;
            if (ok) {
                // @ts-ignore
                showSuccess({message: "Chart deleted"});
            } else {
                throw new Error(errorCode);
            }
        }).catch((err: Error) => {
            console.error(err);
            showError();
        });
    };

    if (loading) {
        return (
            // @ts-ignore
            <Spinner/>
        );
    }

    if (error) {
        console.error(error);
        return (
            <div>
                Failed to load charts
            </div>
        );
    }

    return (
        <div>
            {charts.map((chart: Chart) => (
                <div
                    key={chart.id}
                    className="mb-2 flex items-center justify-between"
                >
                    {onSelect ? (
                        <button
                            type="button"
                            className="px-2 py-1 flex-1 rounded-md hover:bg-black/10"
                            onClick={() => onSelect(chart.id)}
                        >
                            {chart.title}
                        </button>
                    ) : (
                        <Link
                            to={LINKS.editChart(tableId, chart.id)}
                            className="px-2 py-1 flex-1 rounded-md hover:bg-black/10"
                        >
                            {chart.title}
                        </Link>
                    )}

                    {/* @ts-ignore */}
                    <IconWrapper
                        itemId={`delete-chart-${chart.id}`}
                        tooltip="Delete chart"
                        icon={<FiDelete/>}
                        size="small"
                        onMouseDown={doDeleteChart.bind(null, chart.id)}
                        style={{zIndex: 10}}
                    />
                </div>
            ))}
        </div>
    );
}
