import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {DataGrid} from "src/components/DataGrid/index.jsx";
import {Button} from "src/primitives/button.jsx";
import {ErrorComponent} from "src/components/ErrorComponent/";


const PREVIEW_MODES = {
    CHART: "CHART",
    TABLE: "TABLE"
};


export function ResultPreview({options, xAxis}) {
    const [mode, setMode] = useState(PREVIEW_MODES.CHART);

    const formattedData = useMemo(() => {
        return options.dataset[0].source.slice();
    }, [options]);

    const dataColumns = useMemo(() => {
        const axisCol = {id: xAxis, header: xAxis};

        // Filter out regression series. Only include series that don't have own 'data'
        // (aka. they reference the dataset).
        const dataSeries = options.series.filter((item) => !item.data);
        return [axisCol, ...dataSeries.map(({encode, name}) => ({id: encode.y, header: name}))];
    }, [options, xAxis]);

    const defaultSorting = [{id: xAxis}];

    return (
        <div className="w-full h-full flex flex-col">
            <div className="flex-1 flex items-center justify-center overflow-auto p-4">
                {mode === "CHART" && (
                    <ErrorComponent
                        fallback={(
                            <div>Failed to render chart preview</div>
                        )}
                    >
                        <ChartWrapper
                            option={options}
                            // TODO: ReplaceMerge only when series are removed!
                            // https://stackoverflow.com/questions/77801894/is-there-a-way-to-remove-all-series-without-having-to-reload-the-chart-in-echart
                            settings={{replaceMerge: ["series"]}}
                        />
                    </ErrorComponent>
                )}

                {mode === "TABLE" && (
                    <div className="w-full mx-8">
                        <DataGrid
                            fullWidth={true}
                            data={formattedData}
                            columns={dataColumns}
                            defaultSorting={defaultSorting}
                        />
                    </div>
                )}
            </div>

            <div className="px-4 py-4 border-t border-t-black/15">
                <div className="mx-auto flex items-center justify-center gap-2">
                    <Button
                        onClick={() => setMode(PREVIEW_MODES.CHART)}
                        theme={mode === PREVIEW_MODES.CHART ? "secondary" : "outlineBlack"}
                        size="xs"
                    >
                        Chart
                    </Button>
                    <Button
                        onClick={() => setMode(PREVIEW_MODES.TABLE)}
                        theme={mode === PREVIEW_MODES.TABLE ? "secondary" : "outlineBlack"}
                        size="xs"
                    >
                        Table
                    </Button>
                </div>
            </div>
        </div>
    );
}

ResultPreview.propTypes = {
    xAxis: PropTypes.string,
    options: PropTypes.shape({
        dataset: PropTypes.arrayOf(PropTypes.shape({
            source: PropTypes.array
        })),
        series: PropTypes.array
    })
};
