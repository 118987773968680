import {useMemo} from "react";
import {ApolloError, ApolloQueryResult, gql, useQuery} from "@apollo/client";
import {Chart} from "src/types/chart";
import {CHART_FIELDS} from "../fragments.js";


export const GET_CHARTS_QUERY = gql`
    ${CHART_FIELDS}
    query GetCharts ($tableId: String!) {
        charts (tableId: $tableId) {
            ...ChartFields
        }
    }
`;


interface GetChartsResult {
    charts: Chart[];
    loading: boolean;
    error?: ApolloError | undefined;
    refetch: () => Promise<ApolloQueryResult<GetChartsResult>>;
}


export function useGetCharts(tableId: string): GetChartsResult {
    const variables = {tableId};
    const {data, loading, error, refetch} = useQuery(GET_CHARTS_QUERY, {variables});

    const charts = useMemo(() => {
        return data?.charts || [];
    }, [data]);

    return {
        charts, loading, error, refetch
    };
}
