import {useMemo} from "react";
import {ApolloError, gql, useQuery} from "@apollo/client";
import {DASHBOARD_FIELDS} from "src/api/fragments.js";
import {Dashboard} from "src/types/dashboard";


export const GET_DASHBOARD_QUERY = gql`
    ${DASHBOARD_FIELDS}
    query GetDashboard($tableId: String!) {
        dashboard (tableId: $tableId) {
            ... DashboardFields
        }
    }
`;

export interface GetDashboardResult {
    data?: Dashboard;
    loading: boolean;
    error?: ApolloError | undefined;
}


export function useGetDashboard(tableId: string): GetDashboardResult {
    const {loading, error, data} = useQuery(GET_DASHBOARD_QUERY, {variables: {tableId}});

    const dashboard = useMemo(() => {
        return data?.dashboard;
    }, [data]);

    return {data: dashboard, loading, error};
}
