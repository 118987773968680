import React, {JSX, useCallback, useEffect, useMemo, useRef} from "react";
import {useGetTableChatHistory} from "src/api/tableChat/useGetTableChatHistory";
import {ChatHistoryItem} from "src/types/chat";
import {useChatStream} from "src/pages/Home/pages/TablePage/components/TableChatArea/useChatStream";

import {Spinner} from "src/primitives/spinner";
import {smoothScrollToBottom} from "src/pages/Home/pages/TablePage/components/TableChatArea/utils";
import {ChatHeader} from "./components/ChatHeader";
import {PromptArea} from "./components/PromptArea";
import {ChatHistory} from "./components/ChatHistory";


interface TableChatAreaProps {
    tableId: string;
    closeChat: () => void;
}


export function TableChatArea({tableId, closeChat}: TableChatAreaProps): JSX.Element {
    const chatAreaRef = useRef<HTMLDivElement>(null);
    const intervalRef = useRef<number | null>(null);
    const {data, loading, error, refetch} = useGetTableChatHistory(tableId);

    const onCompleted = () => {
        // console.log("stream completed");
        refetch().then(() => {
            // console.log("History updated");
            if (intervalRef.current) {
                window.clearInterval(intervalRef.current);
                intervalRef.current = null;
                scrollToBottom(1);
            }
        });
    };

    const [streamChat, chatResult] = useChatStream({onCompleted});

    const historyItems = useMemo((): ChatHistoryItem[] => {
        if (Array.isArray(data?.history)) {
            return [...data.history];
        }
        return [];
    }, [data]);

    const makeQuestion = useCallback(async (question: string) => {
        scrollToBottom(150);
        intervalRef.current = window.setInterval(scrollToBottom, 500);
        await streamChat({question, tableId});
    }, [streamChat, tableId]);

    function scrollToBottom(delay = 50) {
        setTimeout(() => {
            if (chatAreaRef.current) {
                smoothScrollToBottom(chatAreaRef.current);
            }
        }, delay);
    }

    useEffect(() => {
        scrollToBottom(50);
    }, [loading]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const isSomethingLoading = chatResult.isLoading || loading;

    return (
        <div
            className="h-full w-full flex flex-col relative"
        >
            <ChatHeader closeChat={closeChat}/>
            <div
                ref={chatAreaRef}
                className="flex-1 flex flex-col overflow-y-scroll overflow-x-hidden p-4 pb-16"
            >
                <ChatHistory
                    tableId={tableId}
                    historyItems={historyItems}
                />
                {isSomethingLoading && chatResult.history && (
                    <ChatHistory
                        tableId={tableId}
                        historyItems={chatResult.history}
                    />
                )}
            </div>

            <div className="flex flex-col">
                <div className="h-4 p-4">
                    {isSomethingLoading && (
                        <div className="flex items-center gap-2">
                            <Spinner size="xs" theme="black"/>
                            <div className="monospace text-xs">Loading...</div>
                        </div>
                    )}
                </div>
                <PromptArea
                    tableId={tableId}
                    makeQuestion={makeQuestion}
                    isLoading={chatResult.isLoading}
                />
            </div>

        </div>
    );
}
