import {useMemo} from "react";
import {ApolloError, gql, useQuery, ApolloQueryResult, OperationVariables} from "@apollo/client";
import {ChatHistoryItem} from "src/types/chat";
import {CHAT_HISTORY_FIELDS} from "src/api/fragments";


export const GET_TABLE_CHAT_HISTORY = gql`
    ${CHAT_HISTORY_FIELDS}
    query GetTableChatHistory($tableId: String!) {
        tableChatHistory (tableId: $tableId) {
            ... TableChatHistoryFields
        }
    }
`;

interface QueryResponse {
    loading: boolean;
    error: ApolloError | undefined;
    refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
}

interface TableChatHistory {
    id: string;
    tableId: string;
    userId: string;
    history: ChatHistoryItem[];
}

interface TableChatHistoryResponse extends QueryResponse {
    data: TableChatHistory;
}

export function useGetTableChatHistory(tableId: string): TableChatHistoryResponse {
    const {data, loading, error, refetch} = useQuery(GET_TABLE_CHAT_HISTORY, {
        variables: {tableId},
        notifyOnNetworkStatusChange: true
    });

    const result = useMemo(() => {
        return data?.tableChatHistory || [];
    }, [data]);

    return {
        data: result, loading, error, refetch
    };
}
