import React from "react";
import PropTypes from "prop-types";
import {MarkdownContent} from "src/primitives/Markdown";
import {cva} from "cva";
import {useSetActiveDataId} from "src/pages/Home/pages/TablePage/pages/ChatPage/chat_data_context.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiDatabase} from "react-icons/fi";
import {Spinner} from "src/primitives/spinner.jsx";

/* eslint-disable react/no-array-index-key, max-len */

export const chatMessageWrapperClass = cva({
    base: "rounded-lg px-6 py-4 border",
    variants: {
        role: {
            user: "bg-lime-100/80 border-lime-100 text-lime-800 max-w-[75%]",
            assistant: "bg-neutral-100/50 border-neutral-100 text-neutral-900 w-full",
            toolCall: "bg-neutral-900/85 border-900 text-white text-sm !py-1"
        }
    },
    defaultVariants: {
        role: "assistant"
    }
});


function ChatToolCalls({toolCalls}) {
    const setActiveDataId = useSetActiveDataId();

    return (
        <div className="flex flex-col gap-2">
            {toolCalls.map((toolCall) => {
                const functionArguments = JSON.parse(toolCall.function.arguments);
                const description = functionArguments?.description;

                return (
                    <div
                        key={toolCall.id}
                        className={chatMessageWrapperClass({role: "toolCall"})}
                    >
                        <div className="flex items-center justify-between gap-4">
                            <div className="flex items-center gap-4 ml-[-12px]">
                                <IconWrapper icon={<FiDatabase/>}/>
                                {toolCall.isLoading ? (
                                    <div className="flex items-center gap-4">
                                        <Spinner size="sm" theme="white"/>
                                        <div className="italic py-2">Loading data</div>
                                    </div>
                                ) : (
                                    <div className="italic py-2">{description}</div>
                                )}
                            </div>
                            {!toolCall.isLoading && (
                                <button
                                    type="button"
                                    className="bg-black/90 text-white px-4 py-2 rounded-md text-sm min-w-32"
                                    onClick={() => setActiveDataId(toolCall.id)}
                                >
                                    See results
                                </button>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}


ChatToolCalls.propTypes = {
    toolCalls: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        function: PropTypes.shape({
            name: PropTypes.string,
            arguments: PropTypes.string
        }),
        isLoading: PropTypes.bool
    }))
};


function ChatAssistantMessage({content}) {
    return (
        <div
            className="flex justify-start"
        >
            <div
                className={chatMessageWrapperClass({role: "assistant"})}
            >
                <MarkdownContent content={content}/>
            </div>
        </div>
    );
}

ChatAssistantMessage.propTypes = {
    content: PropTypes.string
};

function ChatSessionHistoryItem({item}) {
    if (item.role === "user") {
        return (
            <div
                className="flex justify-end"
            >
                <div
                    className={chatMessageWrapperClass({role: item.role})}
                >
                    {item.content}
                </div>
            </div>
        );
    }

    if (item.role === "assistant") {
        return (
            <>
                {item.content && <ChatAssistantMessage content={item.content}/>}
                {item.tool_calls && <ChatToolCalls toolCalls={item.tool_calls}/>}
            </>
        );
    }

    if (item.role === "tool") {
        return null;
    }

    return (
        <div>
            {JSON.stringify(item)}
        </div>
    );
}

ChatSessionHistoryItem.propTypes = {
    item: PropTypes.shape({
        role: PropTypes.string,
        content: PropTypes.string,
        tool_calls: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            function: PropTypes.shape({
                name: PropTypes.string,
                arguments: PropTypes.string
            })
        }))
    })
};

export function ChatSessionHistory({session, preview, previewMessages}) {
    const history = session?.history ? [...session.history] : [];

    if (preview) {
        let previewCopy = `${preview}`;
        if (previewCopy.includes("FUNCTION_CALL")) {
            previewCopy = previewCopy.replace("FUNCTION_CALL", "");
            history.push({
                role: "assistant",
                tool_calls: [{
                    id: "mock_id",
                    function: {
                        name: "fetch_data",
                        arguments: JSON.stringify({description: "Fetching data..."})
                    }
                }]
            });
        }
        history.push({role: "assistant", content: previewCopy});
    }

    if (previewMessages) {
        previewMessages.forEach((message) => {
            if (message.type === "message") {
                history.push({role: "assistant", content: message.content});
            } else if (message.type === "tool_call") {
                history.push({
                    role: "assistant",
                    tool_calls: [{
                        id: message.id,
                        function: {
                            name: message.function,
                            arguments: JSON.stringify({description: message.description})
                        },
                        isLoading: message.isLoading
                    }]
                });
            }
        });
    }

    return (
        <div className="flex flex-col gap-6">
            {history.map((item, index) => {
                return (
                    <ChatSessionHistoryItem
                        key={index}
                        item={item}
                    />
                );
            })}
        </div>
    );
}


ChatSessionHistory.propTypes = {
    session: PropTypes.shape({
        id: PropTypes.string,
        history: PropTypes.arrayOf(PropTypes.object)
    }),
    preview: PropTypes.node,
    previewMessages: PropTypes.arrayOf(PropTypes.object)
};
