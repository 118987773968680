import {useCallback} from "react";
import {ApolloCache, gql, useMutation} from "@apollo/client";


const MUTATION = gql`
    mutation DeleteTableChatHistory($tableId: String!) {
        deleteTableChatHistory(tableId: $tableId) {
            ok
            errorCode
            id
        }
    }
`;


// eslint-disable-next-line
function removeChatHistoryFromCache(cache: ApolloCache<any>, response: any): void {
    const {ok, id} = response.data.deleteTableChatHistory;
    if (!ok) {
        return;
    }
    const model = {
        __typename: "TableChatHistory",
        id
    };
    const normalizedId = cache.identify(model);
    cache.evict({id: normalizedId});
    cache.gc();
}

interface Response {
    ok: boolean;
    errorCode: string | null;
    id: string | null;
}

type DeleteTableChatHistoryOptions = [
    doMutate: () => Promise<Response>,
    result: object | null
]

export function useDeleteTableChatHistory(tableId: string): DeleteTableChatHistoryOptions {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback(async () => {
        const response = await mutation({
            variables: {
                tableId
            },
            // eslint-disable-next-line
            update(cache, mutationResponse: any) {
                removeChatHistoryFromCache(cache, mutationResponse);
            }
        });
        return response.data.deleteTableChatHistory;
    }, [mutation, tableId]);

    return [doMutate, {loading, error, result: data?.deleteTableChatHistory}];
}
