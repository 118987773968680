import React, {JSX, useEffect, useRef, useState} from "react";
import debounce from "lodash.debounce";
import equal from "fast-deep-equal";
import {useUpdateChart} from "src/api/charts/useUpdateChart";
import {useUpdateChartTitle} from "src/api/charts/useUpdateChartTitle";
import {Chart} from "src/types/chart";
import {Spinner} from "src/primitives/spinner";


export interface UpdateChartOptionsProps {
    chart: Chart;
    latestOptions: object | null;
    latestTitle: string | null;
}

const INDICATOR_TIMEOUT = 500;
const DEBOUNCE_WAIT = 2000;


export function UpdateChartOptions({
    chart,
    latestTitle,
    latestOptions
}: UpdateChartOptionsProps): JSX.Element {
    const [updateChart] = useUpdateChart(chart.id);
    const [updateChartTitle] = useUpdateChartTitle(chart.id);
    const [isUpdatingChartOptions, setIsUpdatingChartOptions] = useState<boolean>(false);
    const [isUpdatingChartTitle, setIsUpdatingChartTitle] = useState<boolean>(false);

    const debouncedUpdateChart = useRef(
        debounce(async (options: object) => {
            // console.log("Update chart: ", options);
            // @ts-ignore
            await updateChart(options);
            setIsUpdatingChartOptions(false);
        }, DEBOUNCE_WAIT)
    ).current;

    const debouncedUpdateChartTitle = useRef(
        debounce(async (newTitle: string) => {
            // console.log("Update chart title: ", newTitle);
            // @ts-ignore
            await updateChartTitle(newTitle);
            setIsUpdatingChartTitle(false);
        }, DEBOUNCE_WAIT)
    ).current;

    useEffect(() => {
        const hasConfigChanged = latestOptions?.config && !equal(chart.config, latestOptions?.config);
        const hasSeriesChanged = latestOptions?.series && !equal(chart.series, latestOptions?.series);

        if (hasConfigChanged || hasSeriesChanged) {
            window.setTimeout(() => {
                setIsUpdatingChartOptions(true);
            }, INDICATOR_TIMEOUT);
            debouncedUpdateChart(latestOptions);
        }

        return () => {
            debouncedUpdateChart.cancel();
        };
    }, [chart, latestOptions, debouncedUpdateChart]);

    useEffect(() => {
        if (chart?.title !== latestTitle && latestTitle) {
            window.setTimeout(() => {
                setIsUpdatingChartTitle(true);
            }, INDICATOR_TIMEOUT);
            debouncedUpdateChartTitle(latestTitle);
        }

        return () => {
            debouncedUpdateChartTitle.cancel();
        };
    }, [chart.title, latestTitle, debouncedUpdateChartTitle]);

    const isSomethingLoading = isUpdatingChartTitle || isUpdatingChartOptions;

    return (
        <div className="monospace text-xs text-neutral-500">
            {isSomethingLoading ? (
                <div className="fade-in flex items-center gap-2">
                    {/* @ts-ignore */}
                    <Spinner size="xs"/>
                    <div>Autosaving</div>
                </div>
            ) : (
                <div className="fade-in">
                    <span>Up to date</span>
                </div>
            )}
        </div>
    );
}
