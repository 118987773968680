import {LayoutItem} from "src/types/dashboard";
import {generateId} from "src/utils/id";


export function sortGridItems(items: LayoutItem[]): LayoutItem[] {
    return [...items].sort((a, b) => {
        // First compare y coordinates
        if (a.y !== b.y) {
            return a.y - b.y;
        }
        // If y coordinates are equal, compare x coordinates
        return a.x - b.x;
    });
}


export function newGridItem(existingLayout: LayoutItem[], defaultWidth = 2, defaultHeight = 4): LayoutItem {
    // Grid assumptions - adjust these based on your grid settings
    // Typical default for React-Grid-Layout
    const GRID_COLUMNS = 6;
    // Prevent infinite loops
    const MAX_ATTEMPTS = 100;

    // Create new item base
    const newItem = {
        w: defaultWidth,
        h: defaultHeight,
        i: generateId()
    };

    // Find the maximum y position currently occupied
    const maxY = existingLayout.reduce((max, item) => {
        return Math.max(max, item.y + item.h);
    }, 0);

    // Try to find a spot starting from top-left
    let attempts = 0;

    // First try: place at x:0 of the next available row
    for (let x = 0; x <= GRID_COLUMNS - defaultWidth; x++) {
        const potentialPosition = {
            ...newItem,
            x,
            y: maxY
        };

        if (!doesOverlap(potentialPosition, existingLayout)) {
            return potentialPosition;
        }
    }

    // If that fails, try to find a spot in existing rows
    for (let y = 0; y <= maxY && attempts < MAX_ATTEMPTS; y++) {
        for (let x = 0; x <= GRID_COLUMNS - defaultWidth; x++) {
            const potentialPosition = {
                ...newItem,
                x,
                y
            };

            if (!doesOverlap(potentialPosition, existingLayout)) {
                return potentialPosition;
            }
        }
        attempts++;
    }

    // Fallback: add to new row if no space found
    return {
        ...newItem,
        x: 0,
        y: maxY
    };
}

// Helper function to check if a position overlaps with existing items
function doesOverlap(newItem: LayoutItem, existingLayout: LayoutItem[]): boolean {
    const newRight = newItem.x + newItem.w;
    const newBottom = newItem.y + newItem.h;

    return existingLayout.some((item) => {
        const itemRight = item.x + item.w;
        const itemBottom = item.y + item.h;

        return (
            newItem.x < itemRight
            && newRight > item.x
            && newItem.y < itemBottom
            && newBottom > item.y
        );
    });
}
