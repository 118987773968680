import React from "react";
import PropTypes from "prop-types";
import {Spinner as ChakraSpinner} from "@chakra-ui/react";


const sizeToThickness = {
    xs: "1px",
    sm: "2px",
    md: "2px",
    lg: "3px",
    xl: "4px"
};

const themeToColor = {
    green: "#16a34a",
    blue: "#3b82f6",
    orange: "#f97316",
    lime: "#bbf451", // lime-300
    white: "rgba(255, 255, 255, 0.75)",
    black: "rgba(0, 0, 0, 0.75)",
    gray: "rgba(38, 36, 36, 0.75)"
};


export function Spinner({size, theme, speed}) {
    return (
        <ChakraSpinner
            size={size}
            speed={`${speed}s`}
            thickness={sizeToThickness[size]}
            color={themeToColor[theme]}
        />
    );
}

Spinner.propTypes = {
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    theme: PropTypes.oneOf(["green", "blue", "orange", "lime", "white", "black", "gray"]),
    speed: PropTypes.number
};

Spinner.defaultProps = {
    size: "lg",
    speed: 0.8
};
