import {useCallback, useEffect, useMemo} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import {formatQueryResult} from "src/api/utils.js";

const QUERY = gql`
query MakeQuery($sql: String!, $workspaceId: String!) {
  query (sql: $sql, workspaceId: $workspaceId) {
    id
    time
    rows
    columns
    totalRows
  }
}
`;

export function useMakeQuery(workspaceId) {
    const [query, {
        data, loading, error, refetch
    }] = useLazyQuery(QUERY);

    const result = useMemo(() => {
        if (!data || !data.query) {
            return null;
        }
        return formatQueryResult(data.query);
    }, [data]);
    const errors = useMemo(() => {
        if (!error) {
            return null;
        }
        // Generally we are only interested in the GraphqlErrors
        return error.graphQLErrors.map(({message}) => message);
    }, [error]);
    const makeQuery = useCallback((sql) => {
        return query({variables: {sql, workspaceId}});
    }, [query, workspaceId]);

    return [makeQuery, {
        result, loading, errors, refetch
    }];
}

export function usePreview(tableId) {
    const [makeQuery, response] = useMakeQuery();

    useEffect(() => {
        if (!tableId) {
            return;
        }

        const sql = `
            SELECT *
            FROM ${tableId}
            ORDER BY _id
            LIMIT 1000;
        `;
        makeQuery(sql);
    }, [tableId, makeQuery]);

    return response;
}
