import React, {JSX} from "react";


interface ChatHeaderProps {
    closeChat: () => void;
}


export function ChatHeader({closeChat}: ChatHeaderProps): JSX.Element {
    return (
        <div className="h-12 border-b border-black/20 flex items-center justify-between">
            <div className="ml-6">
                Chat assistant
            </div>
            <div className="mr-6">
                <button
                    type="button"
                    onClick={closeChat}
                    className="text-sm hover:underline"
                >
                    Hide chat
                </button>
            </div>
        </div>
    );
}
