import {useCallback} from "react";
import {useGetDashboard} from "src/api/dashboard/useGetDashboard";
import {useUpdateDashboard} from "src/api/dashboard/useUpdateDashboard";
import {newGridItem} from "src/pages/Home/pages/TablePage/pages/TableDashboardPage/pages/TableDashboard/Layout/utils";
import {formatLayoutItems} from "./utils";


type UseAddDashboardItemType = [
    () => Promise<void>,
    {
        loading: boolean
    }
];

export function useAddDashboardItem(tableId: string): UseAddDashboardItemType {
    const {data} = useGetDashboard(tableId);
    const [updateDashboard, {loading}] = useUpdateDashboard(tableId);

    const func = useCallback(async () => {
        const layoutItems = formatLayoutItems(data?.layout);
        const newItem = newGridItem(layoutItems);
        const updatedItems = [...layoutItems, newItem];
        const result = await updateDashboard({layout: updatedItems});
        console.log("Added item to dashboard: ", result.dashboard);
    }, [updateDashboard, data]);

    return [func, {loading}];
}
