import React, {ReactNode} from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
// eslint-disable-next-line import/no-extraneous-dependencies
import remarkGfm from "remark-gfm";
import "./style.css";
import {Code} from "src/primitives/Terminal/code.jsx";
import {createIdFromText} from "./utils";


interface MarkdownRenderProps {
    children: ReactNode;
}

const MarkdownRenders = {
    table: ({children}: MarkdownRenderProps) => (
        <table
            className="markdown-table text-xs monospace"
        >
            {children}
        </table>
    ),
    pre: ({children}: MarkdownRenderProps) => {
        if (!children) {
            return null;
        }

        // @ts-ignore
        const code = children.props.children;
        // @ts-ignore
        const {className} = children.props;
        const language = className ? className.split("-")[1] : null;

        if (!language) {
            // Most likely the code block is empty
            return null;
        }

        return (
            <Code code={code} language={language} showLineNumbers={false}/>
        );
    },
    h1: ({children}: MarkdownRenderProps) => {
        return <h1 id={createIdFromText(children?.toString())}>{children}</h1>;
    },
    h2: ({children}: MarkdownRenderProps) => {
        return <h2 id={createIdFromText(children?.toString())}>{children}</h2>;
    },
    h3: ({children}: MarkdownRenderProps) => {
        return <h3 id={createIdFromText(children?.toString())}>{children}</h3>;
    },
    h4: ({children}: MarkdownRenderProps) => {
        return <h4 id={createIdFromText(children?.toString())}>{children}</h4>;
    }
};

interface MarkdownContentProps {
    content: string;
}

export function MarkdownContent({content}: MarkdownContentProps) {
    return (
        <Wrapper>
            <Markdown
                remarkPlugins={[remarkGfm]}
                components={MarkdownRenders}
            >
                {content}
            </Markdown>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    & h1, h2, h3, h4 {
        font-weight: bold;
        scroll-margin: 80px;
    }
    & h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
    & h2 {
        font-size: 1.5rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    & h3 {
        font-size: 1.25rem;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }
    & h4 {
        font-size: 1rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }
    & p {
        margin-bottom: 1rem;
        line-height: 28px;
    }
    & ul,
    & ol {
        margin-left: 1rem;
        margin-bottom: 1rem;
    }

    & ul li,
    & ol li {
        margin-left: 1rem;
        margin-bottom: 0.5rem;
    }
    & ul li {
        list-style-type: square;
    }

    & ol li {
        list-style-type: decimal;
    }

    & code {
        font-family: monospace;
    }

    & pre {
        padding: 1rem;
        margin-bottom: 1rem;
        margin-right: 2rem;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 0.5rem;
    }
`;
