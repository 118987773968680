import React, {useCallback, useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {useGetTableData} from "src/api/data/useGetTableData.js";
import {useUpdateChart} from "src/api/charts/useUpdateChart.js";
import {useGetChart} from "src/api/charts/useGetChart";
import {useUpdateChartTitle} from "src/api/charts/useUpdateChartTitle.js";
import {formatDataFromQueryResult} from "src/utils/tableData.js";
import {ChartBuilder} from "src/components/ChartBuilder/index";
import {LINKS} from "src/links.js";
import {DashboardPageLayout} from "../../components/DashboardPageLayout/index.jsx";


export function EditChartPage({tableId, chartId}) {
    const {chart, loading: loadingChart} = useGetChart(chartId);
    const [updateChart] = useUpdateChart(chartId, tableId);
    const [updateChartTitle] = useUpdateChartTitle(chartId);
    const {table} = useGetTable(tableId);
    const [loadData, {result}] = useGetTableData(tableId);

    const handleChange = useCallback((data) => {
        console.log("Save data: ", data);
        updateChart(data);
    }, [updateChart]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChangeDebounced = useCallback(debounce((data) => {
        handleChange(data);
    }, 1200), [handleChange]);

    const handleUpdateChartTitle = (v) => {
        console.log("Title: ", v);
        updateChartTitle(v);
    };

    const columns = table?.columns || [];

    useEffect(() => {
        if (!result) {
            loadData();
        }
    }, [loadData, result]);

    const data = useMemo(() => {
        return result ? formatDataFromQueryResult(result.columns, result.rows) : [];
    }, [result]);

    if (loadingChart) {
        return null;
    }

    return (
        <DashboardPageLayout
            tableId={tableId}
            backLink={LINKS.dashboard(tableId)}
        >
            <ChartBuilder
                chartId={chartId}
                columns={columns}
                data={data}
                onChange={handleChangeDebounced}
                chartTitle={chart?.title}
                updateChartTitle={handleUpdateChartTitle}
                initialConfig={chart?.config?.config}
                initialSeries={chart?.config?.series}
            />
        </DashboardPageLayout>
    );
}

EditChartPage.propTypes = {
    tableId: PropTypes.string.isRequired,
    chartId: PropTypes.string.isRequired
};
