import React, {JSX, useCallback, useState} from "react";
import {useDeleteTableChatHistory} from "src/api/tableChat/useDeleteTableChatHistory";
import {ContentEditableDiv} from "src/primitives/controls/ContentEditable";
import {Button} from "src/primitives/button";
import {generateId} from "src/utils/id";
import {useKeyPress} from "src/utils/useKeyPress";
import {ImCommand} from "react-icons/im";
import {IconWrapper} from "src/primitives/Icon";


interface PromptAreaProps {
    tableId: string;
    makeQuestion: (question: string) => Promise<void>;
    isLoading: boolean;
}


export function PromptArea({tableId, makeQuestion, isLoading}: PromptAreaProps): JSX.Element {
    const [key, setKey] = useState(generateId());
    const [deleteTableChatHistory] = useDeleteTableChatHistory(tableId);
    // const [makeQuestion, {loading}] = useMakeQuestion(tableId);
    const [question, setQuestion] = useState("");

    const handleSubmit = useCallback(async () => {
        if (!question) {
            return;
        }

        try {
            await makeQuestion(question);
            setKey(generateId());
            setQuestion("");
        } catch (e) {
            console.error(e);
        }
    }, [makeQuestion, question, setKey, setQuestion]);

    useKeyPress(handleSubmit, ["Enter"], {metaKey: true, shift: false});

    return (
        <div
            className="p-6 h-48"
        >
            <form
                className="flex flex-col gap-4"
                onSubmit={handleSubmit}
            >
                <ContentEditableDiv
                    key={key}
                    // eslint-disable-next-line max-len
                    className="rounded-md p-2 text-neutral-800 border border-neutral-200 h-20 overflow-y-auto bg-white/80"
                    onChange={setQuestion}
                    placeholder={!question ? "Ask me something" : null}
                    autoFocus={true}
                    disabled={isLoading}
                />
                <div
                    className="flex items-center justify-between"
                >
                    <div className="flex items-center gap-2">
                        <Button
                            type="submit"
                            theme="secondary"
                            size="small"
                            onClick={handleSubmit}
                            isLoading={isLoading}
                            isDisabled={!question}
                            style={undefined}
                        >
                            <div>
                                Send
                            </div>
                        </Button>
                        <div className="flex items-center gap-2">
                            <div className="flex items-center gap-1 monospace text-xs">
                                {/* @ts-ignore */}
                                <IconWrapper
                                    icon={<ImCommand/>}
                                    size="xs"
                                />
                                <span>+ Enter</span>
                            </div>
                        </div>
                    </div>

                    <button
                        type="button"
                        onClick={() => deleteTableChatHistory()}
                        className="text-red-500 text-sm hover:underline hover:text-red-600 mr-4"
                    >
                        Clear history
                    </button>
                </div>
            </form>
        </div>
    );
}
