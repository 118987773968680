import {LayoutItem} from "src/types/dashboard";

export function formatLayoutItems(items?: object[]): LayoutItem[] {
    if (!items || !Array.isArray(items)) {
        return [];
    }
    return items.map((item: object) => {
        return {
            // @ts-ignore
            x: item.x,
            // @ts-ignore
            y: item.y,
            // @ts-ignore
            w: item.w,
            // @ts-ignore
            h: item.h,
            // @ts-ignore
            i: item.i
        };
    });
}

export function isChartId(id: string): boolean {
    return id.startsWith("chart_");
}
