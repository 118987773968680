import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {FiChevronLeft} from "react-icons/fi";
import {SidebarDrawer} from "src/primitives/drawer/index.jsx";
import {Title} from "src/primitives/title.jsx";
import {Button} from "src/primitives/button.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {CreateChart} from "../CreateChart/index.jsx";
import {ChartList} from "../ChartList/index";


export function DashboardPageLayout({tableId, children, backLink}) {
    const [showCharts, setShowCharts] = useState(false);

    return (
        <>
            <SidebarDrawer
                isOpen={showCharts}
                close={() => setShowCharts(false)}
                title="Saved charts"
                footer="Footer"
            >
                <div>
                    <ChartList tableId={tableId}/>
                </div>
            </SidebarDrawer>

            <div
                className="w-full h-full flex flex-col"
            >
                <div className="h-16 p-4 flex items-center justify-center border-b border-black/15 bg-neutral-50/5">
                    <div className="flex-1 flex items-center">
                        {backLink && (
                            <Link
                                to={backLink}
                                className="flex items-center hover:bg-black/10 rounded-md pr-4"
                            >
                                <IconWrapper icon={<FiChevronLeft/>} size="small"/>
                                <span>Back</span>
                            </Link>
                        )}
                    </div>

                    <div className="w-64 flex justify-center">
                        <Title style={{margin: 0}}>
                            DASHBOARD
                        </Title>
                    </div>

                    <div className="flex-1 flex justify-end gap-2">
                        <CreateChart tableId={tableId}/>
                        <Button
                            theme="secondary"
                            size="xs"
                            onClick={() => setShowCharts(true)}
                        >
                            Show charts
                        </Button>
                    </div>
                </div>

                <div className="flex-1 overflow-auto">
                    {children}
                </div>
            </div>
        </>
    );
}

DashboardPageLayout.propTypes = {
    tableId: PropTypes.string.isRequired,
    backLink: PropTypes.string,
    children: PropTypes.node
};
