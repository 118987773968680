import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from "@apollo/client";
import {RetryLink} from "@apollo/client/link/retry";
import {getWorkspaceId, WORKSPACE_HEADER_KEY} from "src/utils/urlVariables/workspace.js";
import {generateId} from "src/utils/id.js";
import {getUrlParams} from "src/utils/urlParams.js";
import {getTableVersion, TABLE_VERSION_HEADER_KEY} from "src/utils/urlVariables/tableVersion.js";
import {removeUndefinedValues} from "src/utils/misc.js";
import {config} from "./config.js";

const REQUEST_ID_HEADER_KEY = "X-Request-Id";
const PUBLIC_KEY_HEADER_KEY = "X-Public-Key";
const CLIENT_ID_HEADER_KEY = "X-Client-Id";


export async function getToken() {
    const clerkSession = window.Clerk?.session;
    if (!clerkSession) {
        return null;
    }
    const token = await clerkSession.getToken();
    return token;
}

function getPublicKey() {
    const name = "pk";
    const params = getUrlParams();

    return params[name];
}

const customFetch = (uri, options) => {
    const {operationName} = JSON.parse(options.body);

    return fetch(`${uri}?operation=${operationName}`, options);
};

export const getClient = () => {
    const link = createHttpLink({
        uri: config.GRAPHQL_URL,
        fetch: customFetch
        // credentials: "include",
    });

    const authLink = new ApolloLink(async (operation, forward) => {
        const token = await getToken();
        const newHeaders = {
            [REQUEST_ID_HEADER_KEY]: generateId(),
            authorization: token ? `Bearer ${token}` : null,
            [CLIENT_ID_HEADER_KEY]: config.CLIENT_ID,
            [WORKSPACE_HEADER_KEY]: getWorkspaceId(),
            [TABLE_VERSION_HEADER_KEY]: getTableVersion(),
            [PUBLIC_KEY_HEADER_KEY]: getPublicKey()
        };

        // Get existing context and headers
        const existingContext = operation.getContext();
        const existingHeaders = existingContext.headers || {};

        operation.setContext({
            ...existingContext, // Preserve all existing context
            headers: {
                ...existingHeaders, // Keep existing headers
                ...removeUndefinedValues(newHeaders)
            }
        });
        return forward(operation);
    });
    const retryLink = new RetryLink({
        delay: {
            initial: 300,
            max: Infinity,
            jitter: false
        },
        attempts: {
            max: 3,
            retryIf: (error, _operation) => {
                let operationType = null;
                _operation.query.definitions.forEach((definition) => {
                    if (definition.kind === "OperationDefinition") {
                        operationType = definition.operation;
                    }
                });
                // Should one retry mutations?
                return !!error && operationType !== "mutation";
            }
        }
    });

    // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
    const typePolicies = {
        Workspace: {
            merge: true,
            fields: {
                folders: {
                    merge(existing, incoming) {
                        return incoming;
                    }
                },
                members: {
                    merge(existing, incoming) {
                        return incoming;
                    }
                }
            }
        },
        predictionModels: {
            merge(existing, incoming) {
                return incoming;
            }
        },
        Dashboard: {
            merge: true,
            fields: {
                layout: {
                    merge(existing, incoming) {
                        return incoming;
                    }
                }
            }
        },
        LayoutItem: {
            keyFields: ["i"]
        }
    };

    return new ApolloClient({
        link: authLink.concat(retryLink).concat(link),
        cache: new InMemoryCache({typePolicies})
    });
};
