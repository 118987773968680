import {LayoutItem} from "src/types/dashboard";
import {generateId} from "src/utils/id";

export function getMockLayout(): LayoutItem[] {
    return [{
        x: 0,
        y: 0,
        w: 2,
        h: 5,
        i: generateId()
    }, {
        x: 2,
        y: 0,
        w: 2,
        h: 4,
        i: generateId()
    }, {
        x: 4,
        y: 0,
        w: 2,
        h: 3,
        i: generateId()
    }];
}
