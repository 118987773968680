import React, {JSX} from "react";
import {useGetCharts} from "src/api/charts/useGetCharts";
import {Spinner} from "src/primitives/spinner";
import {Chart} from "src/types/chart";


interface SelectChartProps {
    tableId: string;
    onSelect: (chartId: string) => Promise<void>
}


export function SelectChart({tableId, onSelect}: SelectChartProps): JSX.Element {
    const {charts, loading, error} = useGetCharts(tableId);

    if (error) {
        return (
            <div>
                Failed to load charts
            </div>
        );
    }

    return (
        <div>
            {loading && (
                // @ts-ignore
                <Spinner/>
            )}
            <div className="flex flex-col gap-2">
                {charts.map((chart: Chart) => (
                    <button
                        key={chart.id}
                        type="button"
                        onClick={() => onSelect(chart.id)}
                    >
                        {chart?.id}
                        {chart?.title}
                        {chart?.config?.titleText}
                    </button>
                ))}
            </div>
        </div>
    );
}
