import React, {JSX} from "react";
import {DialogModal} from "src/primitives/modals/Dialog";
import {
    SelectChart
} from "./components/SelectChart";

interface ChartModalProps {
    tableId: string;
    close: () => void;
    isOpen: boolean;
    onSelectChart: (chartId: string) => Promise<void>
}


export function ChartModal({tableId, close, isOpen, onSelectChart}: ChartModalProps): JSX.Element {
    return (
        <DialogModal
            title="Chart"
            isOpen={isOpen}
            close={close}
            footer={undefined}
            size="full"
            verticalAlign="top"
        >
            <div className="w-[80vw]">

                <h2>Select chart</h2>

                <SelectChart
                    tableId={tableId}
                    onSelect={onSelectChart}
                />
            </div>
        </DialogModal>
    );
}
