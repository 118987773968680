export function csvToObjects(csvString: string): object[] {
    // Split the string into rows and filter out empty rows
    const rows = csvString.trim().split("\n").map((row) => row.trim());

    // Get headers from first row
    const headers = rows[0].split(",");

    // Convert remaining rows into objects
    return rows.slice(1).map((row) => {
        const values = row.split(",");
        return headers.reduce((obj, header, index) => {
            // Convert string values to numbers where appropriate
            const value = values[index];
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            obj[header] = Number.isNaN(parseFloat(value)) ? value : Number(value);
            return obj;
        }, {});
    });
}
