import React, {JSX} from "react";
import {Title} from "src/primitives/title";
import {Code} from "src/primitives/Terminal/code";
import {format} from "sql-formatter";


interface DataManipulationResult {
    tool_call_id: string;
    sql: string;
    result: string;
}

export interface ToolDataManipulationProps {
    result: DataManipulationResult;
}

export function ToolDataManipulation({result}: ToolDataManipulationProps): JSX.Element {
    return (
        <div className="p-4 rounded-md bg-black/80 text-white">
            <div className="flex flex-col gap-2">
                {/* @ts-ignore */}
                <Title size="xs">
                    Command:
                </Title>
                <div>
                    {result.sql && (
                        <Code
                            language="sql"
                            code={format(result.sql, {language: "postgresql"})}
                        />
                    )}
                </div>
                {/* @ts-ignore */}
                <Title size="xs">
                    Result:
                </Title>
                <div>
                    {result.result && (
                        <Code
                            language="sql"
                            code={format(result.result, {language: "postgresql"})}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
