import React, {JSX} from "react";
import {ChartConfig, ChartData, ChartSeries} from "src/types/chart";
import {buildOptions} from "src/components/ChartBuilder/options";
import {isEmptyObject} from "src/utils/misc";
import {ChartWrapper} from "./ChartWrapper";


interface ChartRenderProps {
    data: ChartData;
    config: ChartConfig;
    series: ChartSeries[];
    loading: boolean;
}

export function ChartRender({data, config, series, loading}: ChartRenderProps): JSX.Element {
    if (isEmptyObject(config)) {
        return (
            <div>
                Not configured
            </div>
        );
    }
    if (!Array.isArray(data) || data.length === 0) {
        return <div>Loading</div>;
    }

    // console.log(config);
    // console.log(series);
    // console.log(data);

    const options = buildOptions(data, config, series);

    // console.table(data);
    // console.log(options);

    return (
        // @ts-ignore
        <ChartWrapper
            // @ts-ignore
            option={options}
            loading={loading}
        />
    );
}
