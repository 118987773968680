import {useMemo} from "react";
import {ApolloError, ApolloQueryResult, gql, useQuery} from "@apollo/client";
import {Chart} from "src/types/chart";
import {CHART_FIELDS} from "../fragments.js";


export const GET_CHART_QUERY = gql`
    ${CHART_FIELDS}
    query GetChart ($chartId: String!) {
        chart (chartId: $chartId) {
            ...ChartFields
        }
    }
`;


interface GetChartResult {
    chart?: Chart;
    loading: boolean;
    error?: ApolloError | undefined;
    refetch: () => Promise<ApolloQueryResult<GetChartResult>>;
}


export function useGetChart(chartId: string | null): GetChartResult {
    const variables = {chartId};
    const {data, loading, error, refetch} = useQuery(GET_CHART_QUERY, {variables, skip: !chartId});

    const chart = useMemo(() => {
        return data?.chart || null;
    }, [data]);

    return {
        chart, loading, error, refetch
    };
}
