import React, {JSX, useEffect, useMemo} from "react";
import {ChartRender} from "src/charts/ChartRender";
import {ErrorComponent} from "src/components/ErrorComponent";
import {useMakeQuery} from "src/api/data/useMakeQuery";
import {getWorkspaceId} from "src/utils/urlVariables/workspace";
import {formatDataFromQueryResult} from "src/utils/tableData";
import {useGetTableData} from "src/api/data/useGetTableData";
import {useCurrentTableId} from "src/pages/Home/contexts/current_table_context";
import {nanoId} from "src/utils/id";


interface ToolResultChartProps {
    data: Record<string, unknown>[];
    config: object;
}


export function ToolResultChart({data, config}: ToolResultChartProps): JSX.Element {
    const workspaceId = getWorkspaceId();
    const tableId = useCurrentTableId();

    // @ts-ignore
    const [makeQuery, {result, loading, errors}] = useMakeQuery(workspaceId);
    // @ts-ignore
    const [fetchTableData, {result: fetchResult, loading: fetchLoading}] = useGetTableData(tableId);

    useEffect(() => {
        // @ts-ignore
        if (data?.sql && !result) {
            // @ts-ignore
            makeQuery(data.sql);
        }

        // @ts-ignore
    }, [makeQuery, data?.sql, result]);

    const structuredQuery = useMemo(() => {
        // @ts-ignore
        if (data?.query) {
            // @ts-ignore
            return data.query;
        }
        return null;
        // @ts-ignore
    }, [data?.query]);

    useEffect(() => {
        // @ts-ignore
        if (structuredQuery && !fetchResult) {
            // @ts-ignore
            fetchTableData({
                columns: structuredQuery.columns,
                sort: structuredQuery.sort_options,
                filters: structuredQuery.filter_options,
                limit: 10000 // TODO: Make this configurable?
            });
        }
        // @ts-ignore
    }, [fetchTableData, structuredQuery, fetchResult]);

    const chartData = useMemo(() => {
        if (result) {
            return formatDataFromQueryResult(result.columns, result.rows);
        }
        if (fetchResult) {
            return formatDataFromQueryResult(fetchResult.columns, fetchResult.rows);
        }
        return [];
    }, [result, fetchResult]);


    // @ts-ignore
    const chartSeries = config.series.map((seriesConfig) => {
        return {
            // @ts-ignore
            id: `${seriesConfig.column}-${nanoId(4)}`,
            // @ts-ignore
            name: seriesConfig.name,
            // @ts-ignore
            column: seriesConfig.column,
            yAxis: seriesConfig.y_axis,
            regressionType: seriesConfig.regression_type,
            aggregation: null
        };
    });

    const chartConfig = {
        // @ts-ignore
        chartType: config.chartType,
        // @ts-ignore
        titleText: config.title,
        // @ts-ignore
        yAxisLabel: config.yAxis.label,
        // @ts-ignore
        yAxisType: config.yAxis.type,
        // @ts-ignore
        xAxis: config.xAxis.column,
        // @ts-ignore
        xAxisLabel: config.xAxis.label,
        // @ts-ignore
        xAxisType: config.xAxis.type,
        aggregateSeries: false
    };

    if (errors) {
        console.error(errors);
        return (
            <div>
                Failed to load data for chart
            </div>
        );
    }

    // console.log(chartConfig);
    // console.log(chartSeries);

    return (
        <ErrorComponent
            fallback={(
                <div>
                    <div>Failed to render chart</div>
                    <div>{JSON.stringify(config)}</div>
                </div>
            )}
        >
            <ChartRender
                data={chartData}
                loading={loading || fetchLoading}
                // @ts-ignore
                config={chartConfig}
                // @ts-ignore
                series={chartSeries}
            />
        </ErrorComponent>
    );
}
