import {useCallback, useMemo} from "react";
import {ApolloError, gql, useApolloClient, useMutation} from "@apollo/client";
import {DASHBOARD_FIELDS} from "src/api/fragments.js";
import {Dashboard, LayoutItem} from "src/types/dashboard";
import {GET_DASHBOARD_QUERY} from "src/api/dashboard/useGetDashboard";


const MUTATION = gql`
    ${DASHBOARD_FIELDS}
    mutation UpdateDashboard($tableId: String!, $data: UpdateDashboardInput!) {
        updateDashboard (tableId: $tableId, data: $data) {
            ok
            errorCode
            dashboard {
                ... DashboardFields
            }
        }
    }
`;


export interface UpdateDashboardResult {
    data?: Dashboard;
    loading: boolean;
    error?: ApolloError | undefined;
}

export interface UpdateDashboardMutationResult {
    ok: boolean;
    errorCode: string | null;
    dashboard: Dashboard | null;
}

export interface UpdateDashboardInput {
    layout: LayoutItem[];
}

type UpdateDashboardType = [
    (data: UpdateDashboardInput) => Promise<UpdateDashboardMutationResult>,
    UpdateDashboardResult
];


export function useUpdateDashboard(tableId: string): UpdateDashboardType {
    const client = useApolloClient();
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const dashboard = useMemo(() => {
        return data?.updateDashboard?.dashboard;
    }, [data]);

    const doMutate = useCallback(async (inputData: UpdateDashboardInput): Promise<UpdateDashboardMutationResult> => {
        const cachedDashboard = client.cache.readQuery({query: GET_DASHBOARD_QUERY, variables: {tableId}});
        // @ts-ignore
        const updatedDashboard = Object.assign({}, cachedDashboard.dashboard, inputData);

        const expectedResponse = {
            __typename: "DashboardResponse",
            ok: true,
            errorCode: null,
            dashboard: updatedDashboard
        };

        const response = await mutation({
            variables: {
                tableId,
                data: inputData
            },
            optimisticResponse: {
                updateDashboard: expectedResponse
            }
        });
        return response.data.updateDashboard;
    }, [mutation, client, tableId]);

    return [doMutate, {data: dashboard, loading, error}];
}
