import React, {JSX, ReactElement} from "react";
import RGL, {WidthProvider} from "react-grid-layout";
import "react-grid-layout/css/styles.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import "react-resizable/css/styles.css";
import "./style.css";


const ReactGridLayout = WidthProvider(RGL);

interface LayoutItem {
    x: number;
    y: number,
    w: number,
    h: number,
    i: string // Should match "key" for 'items'
}

interface GridLayoutProps {
    items: ReactElement[];
    layout: LayoutItem[];
    rowHeight?: number;
    cols?: number;
    onLayoutChange?: (newLayout: LayoutItem[]) => void;
    isEditable?: boolean;
}

export function GridLayout(props: GridLayoutProps): JSX.Element {
    const {
        layout,
        items,
        onLayoutChange,
        isEditable,
        cols,
        rowHeight,
        ...otherProps
    } = props;

    return (
        <ReactGridLayout
            containerPadding={[0, 0]}
            margin={[10, 10]}
            layout={layout}
            onLayoutChange={onLayoutChange}
            isDraggable={isEditable}
            isResizable={isEditable}
            isDroppable={isEditable}
            cols={cols || 6}
            rowHeight={rowHeight || 30}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
        >
            {items}
        </ReactGridLayout>
    );
}
