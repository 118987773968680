import {gql} from "@apollo/client";


export const QUERY_RESULT_FIELDS = gql`
fragment QueryResultFields on QueryResult {
  id
  time
  rows
  columns
  totalRows
}
`;


export const CORE_TABLE_FIELDS = gql`
fragment CoreTableFields on Table {
  id
  createdAt
  latestVersion
  folderId
  name
  status
  size {
    rows
    bytes
    pretty
  }
  searchIndex
  uniqueConstraints
  publicKey
  publicLinkEnabled
}
`;

export const COLUMN_FIELDS = gql`
fragment ColumnFields on Column {
  id
  tableId
  name
  position
  dataType
  variableType
  semanticType
  displaySettings
  isTarget
  isFeature
  isHidden
}
`;


export const CORE_FOLDER_FIELDS = gql`
fragment CoreFolderFields on Folder {
  id
  createdAt
  name
  parentFolderId
}
`;


export const SHARED_RESOURCE_FIELDS = gql`
fragment SharedResourceFields on SharedResource {
  id
  createdAt
  sharedAt
  status
  resourceId
  resourceType
  sharedByUser {
    id
    firstName
    lastName
    email    
  }
  sharedWithUserEmail
  sharedWithUser {
    id
    firstName
    lastName
    email  
  }
}
`;


export const API_KEY_FIELDS = gql`
fragment ApiKeyFields on ApiKey {
  id
  value
  subjectId
  subjectType
  issuedAt
}
`;

export const CHART_FIELDS = gql`
fragment ChartFields on Chart {
  id
  createdAt
  tableId
  title
  config
  series
  query
}
`;

export const DASHBOARD_FIELDS = gql`
fragment DashboardFields on Dashboard {
  id
  createdAt
  updatedAt
  tableId
  layout {
    x
    y
    w
    h
    i
  }
}
`;


export const CHAT_HISTORY_FIELDS = gql`
fragment TableChatHistoryFields on TableChatHistory {
  id
  tableId
  userId
  history {
    role
    content
    toolCallId
    toolCalls {
      id
      type
      function {
        name
        arguments
      }
    }
  }
}
`;
