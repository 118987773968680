import React, {ReactElement} from "react";
import {LayoutItem} from "src/types/dashboard";
import {GridItem} from "./index";


export function getItemsFromLayout(tableId: string, layout?: LayoutItem[]): ReactElement[] {
    if (!layout) {
        return [];
    }

    return layout.map((item: LayoutItem) => {
        return (
            <div key={item.i}>
                <GridItem
                    id={item.i}
                    tableId={tableId}
                />
            </div>
        );
    });
}
