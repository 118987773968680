import {useCallback, useRef, useState} from "react";
import {getToken} from "src/client";
import {ChatHistoryItem} from "src/types/chat";
import {toCamelCaseObject} from "src/utils/misc";
import {config} from "src/config";


interface ChatStreamResponse {
    isLoading: boolean;
    error: Error | null;
    history: ChatHistoryItem[] | null;
}

interface ChatRequestData {
    question: string;
    tableId: string;
    onCompleted?: () => void;
}

interface ChatStreamOptions {
    onCompleted?: () => void;
}

type ChatStreamType = [
    handleStream: (data?: ChatRequestData) => Promise<void>,
    result: ChatStreamResponse
]


export function useChatStream({onCompleted}: ChatStreamOptions = {}): ChatStreamType {
    const contentRef = useRef<string>("");
    const [history, setHistory] = useState<ChatHistoryItem[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const updateContentState = useCallback(() => {
        requestAnimationFrame(() => {
            try {
                const contentHistory = JSON.parse(contentRef.current);
                const formattedHistory = contentHistory.map(toCamelCaseObject);
                setHistory(formattedHistory);
            } catch (e) {
                console.warn("invalid json");
                console.warn(contentRef.current);
                // incomplete JSON. It's OK!
            }
        });
    }, [setHistory]);

    const handleStream = useCallback(async (data?: ChatRequestData) => {
        const url = `${config.API_URL}/v1/chat`;

        try {
            setIsLoading(true);
            contentRef.current = "";
            setHistory(null);

            const token = await getToken();
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                body: data ? JSON.stringify(data) : null
            });
            if (!response.body) {
                return;
            }
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            // eslint-disable-next-line no-constant-condition
            while (true) {
                // eslint-disable-next-line no-await-in-loop
                const {done, value} = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                // for partial updates
                // contentRef.current += chunk;

                // for full updates
                if (chunk) {
                    // console.log(chunk);
                    // const parts = chunk.match(/\[.*?\]/g);
                    const prefix = "[{\"role\": \"user";
                    const parts = chunk.split(prefix);
                    if (parts) {
                        contentRef.current = prefix + parts[parts.length - 1];
                        updateContentState();
                    }
                }
            }
        } catch (e) {
            console.error("Streaming error:", e);
            // @ts-ignore
            setError(e);
        } finally {
            setIsLoading(false);
            if (onCompleted) {
                onCompleted();
            }
        }
    }, [setIsLoading, updateContentState, setHistory, setError, onCompleted]);

    return [handleStream, {history, isLoading, error}];
}
