export function getRange(start, end, step = 1) {
    const len = Math.floor((end - start) / step) + 1;

    try {
        return Array(len).fill().map((_, index) => start + (index * step));
    } catch (e) {
        console.error("Failed to create range for input: ", start, " - ", end, " - ", step);
        console.error(e);
        return [];
    }
}

export function arrayToMap(items, identifier = "id") {
    return items.reduce((acc, item) => Object.assign(acc, {[item[identifier]]: item}), {});
}

export function safeArrayToMap(items, identifier = "id") {
    return Array.isArray(items) ? arrayToMap(items, identifier) : {};
}


export function safeArrayLength(items) {
    return Array.isArray(items) ? items.length : -1;
}


export function isNullOrUndefined(v) {
    return v === null || v === undefined;
}

export function coalesceNumber(value, defaultValue) {
    return isNullOrUndefined(value) ? defaultValue : value;
}

export function coalesce(value, defaultValue = "") {
    return isNullOrUndefined(value) ? defaultValue : value;
}


export function isNotEmptyArray(items) {
    return Array.isArray(items) && items.length > 0;
}

export function isEmptyObject(item) {
    return !item || Object.keys(item).length === 0;
}

export function removeUndefinedValues(obj) {
    const newObject = {};

    Object.keys(obj).forEach((key) => {
        if (!isNullOrUndefined(obj[key])) {
            newObject[key] = obj[key];
        }
    });

    return newObject;
}

export function groupBy(array, key) {
    return array.reduce((acc, item) => {
        const groupKey = item[key];
        if (!acc[groupKey]) {
            // eslint-disable-next-line no-param-reassign
            acc[groupKey] = [];
        }
        acc[groupKey].push(item);
        return acc;
    }, {});
}

export function groupByUsingMap(array, key) {
    /**
     * Implementation that persists the type of the "key"-variable (if it's a string/number/etc.)
     * @type {Map<any, any>}
     */
    const result = new Map();

    return array.reduce((acc, item) => {
        const groupKey = item[key];

        if (acc.get(groupKey) === undefined) {
            acc.set(groupKey, []);
        }
        acc.get(groupKey).push(item);

        return acc;
    }, result);
}

function extractKeysToObject(obj, keys) {
    return keys.reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
    }, {});
}

function extractKeysToArray(obj, keys) {
    return keys.reduce((acc, key) => {
        acc.push(obj[key]);
        return acc;
    }, []);
}

export function selectColumns(array, columns) {
    return array.map((row) => {
        return extractKeysToObject(row, columns);
    });
}

export function selectColumnsFlat(array, columns) {
    return array.map((row) => {
        return extractKeysToArray(row, columns);
    });
}

export function uniquePairs(array) {
    // Step 1: Create a Map to store unique (x, y) pairs
    const uniqueMap = new Map();

    // Step 2: Populate the Map with unique pairs
    array.forEach(([x, y]) => {
        uniqueMap.set(x, y);
    });

    // Step 3: Convert the Map back to an array of arrays
    return Array.from(uniqueMap.entries());
}

export function deepEqual(obj1, obj2) {
    /**
     * Source:
     * https://medium.com/@stheodorejohn/javascript-object-deep-equality-comparison-in-javascript-7aa227e889d4
     */
    // Base case: If both objects are identical, return true.
    if (obj1 === obj2) {
        return true;
    }
    // Check if both objects are objects and not null.
    if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
        return false;
    }
    // Get the keys of both objects.
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    // Check if the number of keys is the same.
    if (keys1.length !== keys2.length) {
        return false;
    }
    // Iterate through the keys and compare their values recursively.
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }
    // If all checks pass, the objects are deep equal.
    return true;
}


export function toCamelCase(stringValue) {
    return stringValue.replace(/[_-]([a-z])/g, (match, group1) => group1.toUpperCase());
}

export function toCamelCaseObject(obj) {
    return Object.keys(obj).reduce((acc, key) => {
        const camelCaseKey = toCamelCase(key);
        acc[camelCaseKey] = obj[key];
        return acc;
    }, {});
}
