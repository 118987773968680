import React, {JSX} from "react";
import {ToolCall} from "src/types/chat";
import {toCamelCaseObject} from "src/utils/misc";
import {ToolDataManipulation} from "./components/ToolDataManipulation";
import {ToolResultChart} from "./components/ToolResultChart";
import {ToolResultDataFetching} from "./components/ToolResultDataFetching";


interface ToolMessageProps {
    content: string;
    toolCall: ToolCall;
    toolCallResults: Record<string, object>;
}

const TOOL_NAMES = {
    CHART: "chart_configuration_tool",
    QUERY: "data_fetching_tool",
    STRUCTURED_QUERY: "structured_data_fetching_tool",
    DATA_MANIPULATION: "data_manipulation_tool"
};


export function ToolMessage({content, toolCall, toolCallResults}: ToolMessageProps): JSX.Element {
    const toolName = toolCall.function.name;

    let contentParsed = null;
    try {
        contentParsed = JSON.parse(content);
        if (!Array.isArray(contentParsed)) {
            // @ts-ignore
            contentParsed = toCamelCaseObject(contentParsed);
        }
    } catch (e) {
        console.info(e);
        console.log(content);
    }

    if (toolName === TOOL_NAMES.CHART) {
        const chartData = toolCallResults[contentParsed.dataToolCallId];
        if (!chartData) {
            return (
                <div>
                    <div>
                        Failed to parse chart data
                    </div>
                </div>
            );
        }

        return (
            <ToolResultChart
                // @ts-ignore
                data={chartData}
                config={contentParsed}
            />
        );
    }

    if (toolName === TOOL_NAMES.QUERY) {
        return (
            <ToolResultDataFetching
                result={contentParsed}
            />
        );
    }

    if (toolName === TOOL_NAMES.STRUCTURED_QUERY) {
        return (
            <ToolResultDataFetching
                result={contentParsed}
            />
        );
    }

    if (toolName === TOOL_NAMES.DATA_MANIPULATION) {
        return (
            <ToolDataManipulation
                result={contentParsed}
            />
        );
    }

    return (
        <div
            className="p-4 rounded-md bg-black/80 text-white"
        >
            <div>{toolName}</div>
        </div>
    );
}
