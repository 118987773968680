import React, {JSX, useCallback} from "react";
import {TableChatArea} from "src/pages/Home/pages/TablePage/components/TableChatArea/index";


interface ChatAreaWrapperProps {
    tableId: string;
}


export function ChatAreaWrapper({tableId}: ChatAreaWrapperProps): JSX.Element | null {
    const [isOpen, setIsOpen] = React.useState(false);

    const closeChat = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const openChat = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    return (
        <>
            {isOpen && (
                <div
                    // eslint-disable-next-line max-len
                    className="basis-[620px] w-[620px] overflow-x-hidden flex-grow-0 flex-shrink-0 h-full border-l border-black/20"
                    style={{background: "#f9f8f3"}}
                >
                    <TableChatArea tableId={tableId} closeChat={closeChat}/>
                </div>
            )}

            {!isOpen && (
                <div className="fixed bottom-12 right-12 z-10">
                    <button
                        type="button"
                        onClick={openChat}
                        className="bg-black/85 hover:bg-black/95 text-white px-4 py-3 rounded-xl text-sm"
                    >
                        Show chat
                    </button>
                </div>
            )}
        </>
    );
}
