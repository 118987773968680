import React, {JSX, useEffect, useMemo, useState} from "react";
import {SelectChartType} from "src/components/ChartBuilder/components/SelectChartType/index.jsx";
import {ChartOptions} from "src/components/ChartBuilder/components/ChartOptions/index.jsx";
import {ErrorComponent} from "src/components/ErrorComponent";
import {SelectXaxis} from "src/components/ChartBuilder/components/SelectXaxis/index.jsx";
import {buildOptions} from "src/components/ChartBuilder/options";
import {EditSeries} from "src/components/ChartBuilder/components/EditSeries/index.jsx";
import {ControlWrapper} from "src/components/ChartBuilder/layouts.jsx";
import {arrayToMap, isEmptyObject} from "src/utils/misc.js";
import {getAxisTypeByColumn} from "src/components/ChartBuilder/utils.js";
import {Title} from "src/primitives/title.jsx";
import {ConfigSeriesItem} from "src/components/ChartBuilder/components/ConfigSeriesItem/index.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiArrowLeft} from "react-icons/fi";
import {Checkbox} from "src/primitives/controls/Checkbox.jsx";
import {ResultPreview} from "src/components/ChartBuilder/components/ResultPreview/index.jsx";
import {emptySeries} from "src/components/ChartBuilder/components/EditSeries/utils.js";
import {TextEditable} from "src/primitives/controls/index.jsx";
import {TableColumn} from "src/types";
import {ChartConfig, ChartData, ChartSeries} from "src/types/chart";
import {getDefaultConfig} from "./config";


interface ChartBuilderProps {
    columns: TableColumn[];
    data: ChartData;
    onChange: (data: object) => void;
    chartTitle: string;
    updateChartTitle: (title: string) => void;
    initialConfig: ChartConfig;
    initialSeries: ChartSeries[];
}


export function ChartBuilder({
    columns,
    data,
    onChange,
    chartTitle,
    updateChartTitle,
    initialConfig,
    initialSeries
}: ChartBuilderProps): JSX.Element {
    const [activeSeriesId, setActiveSeriesId] = useState(null);
    const [series, setSeries] = useState(
        initialSeries || [emptySeries({column: "sold_price", name: "Sold price"})]
    );
    const [chartConfig, setChartConfig] = useState(
        isEmptyObject(initialConfig) ? getDefaultConfig() : initialConfig
    );

    const columnsMap = arrayToMap(columns, "name");

    const handleSelectChartType = (chartType: string) => {
        setChartConfig((prevConfig) => ({...prevConfig, chartType}));
    };

    const handleUpdateChartOptions = (newOptions: object) => {
        setChartConfig((prevConfig) => Object.assign({}, prevConfig, newOptions));
    };

    const handleUpdateXaxis = (newAxis: string) => {
        const column = columnsMap[newAxis];
        if (!column) {
            return;
        }

        setChartConfig((prevConfig) => {
            return Object.assign({}, prevConfig, {
                xAxis: newAxis,
                xAxisLabel: newAxis,
                xAxisType: getAxisTypeByColumn(column)
            });
        });
    };

    const updateSeriesConfig = (id: string, newData: object) => {
        const newSeries = series.map((seriesItem) => {
            return (seriesItem.id === id) ? Object.assign({}, seriesItem, newData) : seriesItem;
        });
        setSeries(newSeries);
    };

    useEffect(() => {
        if (onChange) {
            onChange({config: chartConfig, series});
        }
    }, [chartConfig, series, onChange]);

    const activeSeries = series.find((item) => item.id === activeSeriesId);

    const options = useMemo(() => {
        // console.log(o);
        return buildOptions(data, chartConfig, series);
    }, [data, chartConfig, series]);


    return (
        <ErrorComponent
            fallback={(
                <div>
                    <div>Failed to render chart</div>
                </div>
            )}
        >
            <div className="w-full h-full flex">
                <div className="w-112 border-r border-black/15 px-8 py-4 overflow-y-auto">
                    <ControlWrapper>
                        {/* @ts-ignore */}
                        <Title>
                            {/* @ts-ignore */}
                            <TextEditable
                                text={chartTitle || "New chart"}
                                onSubmit={updateChartTitle}
                            />
                        </Title>
                    </ControlWrapper>

                    {activeSeriesId && (
                        <>
                            <ControlWrapper>
                                {/* @ts-ignore */}
                                <Title size="xs">
                                    <div className="flex items-center gap-1 ml-[-8px]">
                                        {/* @ts-ignore */}
                                        <IconWrapper
                                            icon={<FiArrowLeft/>}
                                            onClick={setActiveSeriesId.bind(null, null)}
                                            size="xs"
                                        />
                                        <span>
                                            Edit {activeSeries?.name}
                                        </span>
                                    </div>
                                </Title>
                            </ControlWrapper>

                            <ControlWrapper>
                                <ConfigSeriesItem
                                    item={activeSeries}
                                    updateSeriesConfig={updateSeriesConfig}
                                />
                            </ControlWrapper>
                        </>
                    )}

                    {!activeSeriesId && (
                        <>
                            <ControlWrapper>
                                <SelectChartType
                                    onSelectChartType={handleSelectChartType}
                                    value={chartConfig.chartType}
                                />
                            </ControlWrapper>

                            <ControlWrapper>
                                <ChartOptions
                                    onChange={handleUpdateChartOptions}
                                    config={chartConfig}
                                />
                            </ControlWrapper>

                            <ControlWrapper>
                                <SelectXaxis
                                    columns={columns}
                                    onChange={handleUpdateXaxis}
                                    value={chartConfig.xAxis}
                                />
                                <div className="my-1 text-sm">
                                    <Checkbox
                                        name="aggregate"
                                        text="Aggregate"
                                        isChecked={chartConfig.aggregateSeries}
                                        onChange={(n: string, v: string) => {
                                            handleUpdateChartOptions({aggregateSeries: v});
                                        }}
                                    />
                                </div>
                            </ControlWrapper>

                            <ControlWrapper>
                                <EditSeries
                                    columns={columns}
                                    series={series}
                                    updateSeries={setSeries}
                                    setActiveSeriesId={setActiveSeriesId}
                                    aggregateData={chartConfig.aggregateSeries}
                                />
                            </ControlWrapper>
                        </>
                    )}
                </div>

                <div className="flex-1">
                    <ErrorComponent fallback={(
                        <div>
                            Failed to render preview
                        </div>
                    )}
                    >
                        <ResultPreview
                            options={options}
                            xAxis={chartConfig?.xAxis}
                        />
                    </ErrorComponent>
                </div>

            </div>
        </ErrorComponent>
    );
}
