import React from "react";
import {MarkdownContent} from "src/primitives/Markdown";
import {PageMeta} from "src/pages/Web/components/PageMeta";
import {LandingPageWrapper} from "src/pages/Web/pages/LandingPages/LandingPageWrapper";
// @ts-ignore
import content from "./content.md?raw";


export function FitDistributionPage() {
    return (
        <>
            <PageMeta
                title="Fit theoretical distributions"
                description={`
                How to fit distributions to your data.
                `}
            />
            <LandingPageWrapper>
                <MarkdownContent content={content}/>
            </LandingPageWrapper>
        </>
    );
}
