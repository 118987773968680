import React, {JSX, useCallback, useMemo} from "react";
import {useGetDashboard} from "src/api/dashboard/useGetDashboard";
import {useUpdateDashboard} from "src/api/dashboard/useUpdateDashboard";
import {LayoutItem} from "src/types/dashboard";
import {generateId} from "src/utils/id";
import {getMockLayout} from "src/pages/Home/pages/TablePage/pages/TableDashboardPage/pages/TableDashboard/mock";
import {EditChartModal} from "src/pages/Home/pages/TablePage/pages/TableDashboardPage/components/EditChartModal";
import {useGetUrlParam} from "src/utils/urlParams";
import {getItemsFromLayout} from "./GridItem/helper";
import {sortGridItems} from "./Layout/utils";
import {formatLayoutItems} from "./utils";
import {GridLayout} from "./Layout/GridLayout";
import {DashboardPageLayout} from "../../components/DashboardPageLayout";
import {useAddDashboardItem} from "./useAddDashboardItem";
import {useEditChart} from "../../utils";
import {DashboardNavbar} from "./DashboardNavbar";


interface TableDashboardProps {
    tableId: string;
}


export function TableDashboard({tableId}: TableDashboardProps): JSX.Element | null {
    const chartId = useGetUrlParam("chartId");
    const [, cancelEditChart] = useEditChart();
    const {data, loading: loadingDashboard} = useGetDashboard(tableId);
    const [updateDashboard] = useUpdateDashboard(tableId);
    const [addDashboardItem] = useAddDashboardItem(tableId);
    const [isEditable, setIsEditable] = React.useState<boolean>(false);
    const [tempLayout, setTempLayout] = React.useState<LayoutItem[]>([]);
    const dashboardKey = React.useRef<string>(generateId());

    const rowHeight = 80;

    const handleNewLayout = useCallback((newLayout: object[]) => {
        // console.log("New layout: ");
        // console.table(newLayout);
        const newLayoutItems = formatLayoutItems(newLayout);
        setTempLayout(newLayoutItems);
    }, [setTempLayout]);

    const handleSaveLayout = useCallback(async () => {
        if (!tempLayout.length) {
            return;
        }
        const result = await updateDashboard({layout: tempLayout});
        console.log("Updated dashboard: ", result.dashboard);
        setIsEditable(false);
        setTempLayout([]);
    }, [updateDashboard, tempLayout, setIsEditable, setTempLayout]);

    const cancelEditLayout = useCallback(() => {
        setIsEditable(false);
        setTempLayout([]);
        // Force re-render/reset of grid
        dashboardKey.current = generateId();
    }, [setIsEditable, setTempLayout]);

    const makeEditable = useCallback(() => {
        setIsEditable(true);
        // Force re-render/reset of grid
        // dashboardKey.current = generateId();
    }, [setIsEditable]);

    /*
    // Should not memo items, that adds bugs for editing layout.
    // ... is probably fixable
     */
    const items = getItemsFromLayout(tableId, data?.layout);

    const layout = useMemo(() => {
        return sortGridItems(formatLayoutItems(data?.layout));
    }, [data?.layout]);

    if (loadingDashboard) {
        return null;
    }

    return (
        <>

            <EditChartModal
                key={chartId}
                tableId={tableId}
                chartId={chartId}
                isOpen={!!chartId}
                close={cancelEditChart}
            />

            <DashboardPageLayout
                tableId={tableId}
                backLink={undefined}
            >
                <div className="flex flex-col gap-2">
                    <DashboardNavbar
                        isEditable={isEditable}
                        makeEditable={makeEditable}
                        cancelEditLayout={cancelEditLayout}
                        handleSaveLayout={handleSaveLayout}
                        addDashboardItem={addDashboardItem}
                    />

                    <div className="bg-black-2 p-4">
                        <GridLayout
                            key={dashboardKey.current}
                            items={items}
                            layout={layout || getMockLayout()}
                            onLayoutChange={handleNewLayout}
                            rowHeight={rowHeight}
                            isEditable={isEditable}
                        />
                    </div>
                </div>
            </DashboardPageLayout>
        </>
    );
}
