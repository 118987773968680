import React, {JSX} from "react";
import {ToolCall} from "src/types/chat";
import {MarkdownContent} from "src/primitives/Markdown";


interface AssistantMessageProps {
    content: string | null;
    toolCalls?: ToolCall[];
}

export function AssistantMessage({content, toolCalls}: AssistantMessageProps): JSX.Element | null {
    if (!content) {
        return null;
    }

    return (
        <div className="p-4 rounded-md bg-white/60">
            {content && (
                <MarkdownContent content={content}/>
            )}

            {(toolCalls && toolCalls.length > 0) && (
                <div>
                    <p>Making some calls</p>
                    <ul>
                        {toolCalls.map((toolCall) => (
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                            <li
                                key={toolCall.id}
                                // eslint-disable-next-line no-console
                                onClick={() => console.log(toolCall)}
                            >
                                {toolCall.function.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
