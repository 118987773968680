import React, {JSX} from "react";


export interface DashboardNavbarProps {
    isEditable: boolean;
    makeEditable: () => void;
    cancelEditLayout: () => void;
    handleSaveLayout: () => void;
    addDashboardItem: () => void;
}


export function DashboardNavbar({
    isEditable,
    makeEditable,
    cancelEditLayout,
    handleSaveLayout,
    addDashboardItem
}: DashboardNavbarProps): JSX.Element {
    return (
        <div className="flex items-center gap-2">
            {isEditable ? (
                <>
                    <button
                        type="button"
                        onClick={handleSaveLayout}
                        className="px-4 py-2 rounded-md text-sm min-w-32"
                    >
                        Save layout
                    </button>
                    <button
                        type="button"
                        onClick={cancelEditLayout}
                        className="px-4 py-2 rounded-md text-sm min-w-32"
                    >
                        Cancel
                    </button>
                </>
            ) : (
                <button
                    type="button"
                    onClick={makeEditable}
                    className="px-4 py-2 rounded-md text-sm min-w-32"
                >
                    Edit layout
                </button>
            )}
            <button
                type="button"
                onClick={addDashboardItem}
                className="px-4 py-2 rounded-md text-sm min-w-32"
            >
                Add item
            </button>
        </div>
    );
}
