import React, {JSX} from "react";
import {useCreateChart} from "src/api/charts/useCreateChart";
import {Button} from "src/primitives/button";
import {useEditChart} from "../../../utils";
import {useUpdateItemId} from "../useUpdateItemId";


export interface CreateChartProps {
    itemId: string;
    tableId: string;
}

export function CreateChart({itemId, tableId}: CreateChartProps): JSX.Element {
    const [updateItemId] = useUpdateItemId(tableId);
    const [editChart] = useEditChart();

    // @ts-ignore
    const [createChart, {loading}] = useCreateChart(tableId);

    const handleClick = () => {
        // @ts-ignore
        createChart().then((result: object) => {
            // @ts-ignore
            const {ok, errorCode, chart} = result;
            if (!ok) {
                throw new Error(errorCode);
            }
            updateItemId(itemId, chart.id).then((r) => {
                console.log(r);
            });
            editChart(chart.id);
        }).catch((error: Error) => {
            console.error(error);
        });
    };

    return (
        <div>
            <Button
                onClick={handleClick}
                type="button"
                theme="primary"
                size="small"
                isLoading={loading}
                isDisabled={false}
                style={undefined}
            >
                Create chart
            </Button>
        </div>
    );
}
