import {useCallback} from "react";
import {formatLayoutItems} from "src/pages/Home/pages/TablePage/pages/TableDashboardPage/pages/TableDashboard/utils";
import {LayoutItem} from "src/types/dashboard";
import {useGetDashboard} from "src/api/dashboard/useGetDashboard";
import {useUpdateDashboard} from "src/api/dashboard/useUpdateDashboard";


type UpdateIdType = [
    (currentId: string, newId: string) => Promise<void>,
    {
        loading: boolean
    }
];

export function useUpdateItemId(tableId: string): UpdateIdType {
    const {data} = useGetDashboard(tableId);
    const [updateDashboard, {loading}] = useUpdateDashboard(tableId);

    const func = useCallback(async (currentId: string, newId: string) => {
        const layoutItems = formatLayoutItems(data?.layout);
        const updatedItems = layoutItems.map((item: LayoutItem) => {
            if (item.i === currentId) {
                return {
                    ...item,
                    i: newId
                };
            }
            return item;
        });
        const result = await updateDashboard({layout: updatedItems});
        console.log("Updated dashboard: ", result.dashboard);
    }, [updateDashboard, data]);

    return [func, {loading}];
}
