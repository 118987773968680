import {isNumber} from "src/utils/validators.js";
import {DataTypes} from "src/utils/tableData.js";
import {isNullOrUndefined} from "src/utils/misc.js";

// TODO: Make this a config
const LOCALE = "sv-SE";

export function formatSafeString(tableName, maxCharacters = 36) {
    if (typeof tableName !== "string") {
        console.warn("Invalid argument to formatSafeString");
        return "";
    }
    return tableName.replace(" ", "_").trim().toLowerCase().slice(0, maxCharacters);
}


export function formatTitleCase(stringValue) {
    const exceptions = ["of", "the", "and"];

    if (!stringValue) {
        return "";
    }

    const stringParts = stringValue.toLowerCase().split(" ");

    return stringParts.map((word, i) => {
        return exceptions.includes(word) && i !== 0 ? word : word.charAt(0).toUpperCase().concat(word.slice(1));
    }).join(" ");
}

export function formatPrettyString(stringValue) {
    if (!stringValue) {
        return "";
    }
    return formatTitleCase(stringValue.replaceAll("_", " "));
}


export function convertStringToFloat(stringValue) {
    // Use a regular expression to check if the string has the format like '123.123'
    const regex = /^-?\d+(\.\d+)?$/;

    if (regex.test(stringValue)) {
    // If the string matches the pattern, convert it to float
        return parseFloat(stringValue);
    }
    // If the string doesn't match, return null or throw an error
    return null; // or throw new Error('Invalid number format');
}

export function tryConvertStringToFloat(value) {
    const floatValue = convertStringToFloat(value);
    if (floatValue === null) {
        // fallback
        return value;
    }
    return floatValue;
}

export function tryParseFloat(value) {
    try {
        return window.parseFloat(value);
    } catch (e) {
        return null;
    }
}

export function tryParseInt(value) {
    try {
        return window.parseInt(value, 10);
    } catch (e) {
        return null;
    }
}

export function tryParseJson(value) {
    try {
        return JSON.parse(value);
    } catch (e) {
        return value;
    }
}


export function roundNumber(value, decimals = 2) {
    // eslint-disable-next-line no-restricted-properties
    return Math.round(value * 10 ** decimals) / 10 ** decimals;
}


export function formatNumber(value, decimals = 2, {minDecimals} = {}) {
    if (!isNumber(value)) {
        return "";
    }

    const options = {
        minimumFractionDigits: isNullOrUndefined(minDecimals) ? decimals : minDecimals,
        maximumFractionDigits: decimals
    };

    return Number(value).toLocaleString(LOCALE, options);
}


export function formatInteger(value) {
    return formatNumber(value, 0);
}


export function formatPercent(value, decimals = 2) {
    return `${(value * 100).toFixed(decimals)}%`;
}

export function abbreviateNumber(value, decimals = 2) {
    if (!isNumber(value)) {
        return "";
    }
    if (!Number.isFinite(value)) return value.toString();

    /*
    if (!isNumber(decimals)) {
        // integer = 0 decimals
        // eslint-disable-next-line no-param-reassign
        decimals = parseInt(value, 10) === value ? 0 : 2;
    }
    */

    const absNum = Math.abs(value);
    let formattedNumber;

    if (absNum >= 1_000_000_000_000) {
        formattedNumber = `${(absNum / 1_000_000_000_000).toFixed(decimals).replace(/\.00$/, "")}T`;
    } else if (absNum >= 1_000_000_000) {
        formattedNumber = `${(absNum / 1_000_000_000).toFixed(decimals).replace(/\.00$/, "")}B`;
    } else if (absNum >= 1_000_000) {
        formattedNumber = `${(absNum / 1_000_000).toFixed(decimals).replace(/\.00$/, "")}M`;
    } else if (absNum >= 1_000) {
        formattedNumber = `${(absNum / 1_000).toFixed(decimals)
            .replace(/\.00$/, "")}k`;
    } else if (absNum >= 10) {
        formattedNumber = absNum.toFixed(0);
    } else {
        formattedNumber = absNum.toFixed(decimals);
    }

    return value < 0 ? `-${formattedNumber}` : formattedNumber;
}

export function formatDate(dateString) {
    const date = new Date(dateString);

    return date.toLocaleDateString(LOCALE);
}

export function formatTimestamp(dateString) {
    const date = new Date(dateString);

    return date.toLocaleString(LOCALE);
}


export function formatTime(dateString) {
    const date = new Date(dateString);

    return date.getTime();
}

export function smartFormat(value) {
    if (typeof value === "number") {
        if (value === tryParseInt(value)) {
            return formatNumber(value, 0);
        }
        return formatNumber(value, 2);
    }
    return formatPrettyString(value);
}

export function formatByColumn(value, column, fallback = "") {
    if (value === null || value === undefined) {
        return fallback;
    }

    if (column.dataType === DataTypes.DATE) {
        return formatDate(value);
    }
    if (column.dataType === DataTypes.DATETIME) {
        return formatTimestamp(value);
    }
    if (column.dataType === DataTypes.FLOAT) {
        return formatNumber(value, 2);
    }
    if (column.dataType === DataTypes.INTEGER) {
        return formatNumber(value, 0);
    }
    if (column.dataType === DataTypes.STRING) {
        return formatPrettyString(value);
    }
    if (column.dataType === DataTypes.BOOLEAN) {
        return value.toString();
    }
    return value;
}


export function humanFileSize(bytes, si = true, decimals = 2) {
    /**
     * Format bytes as human-readable text.
     * Source: https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
     *
     * @param bytes Number of bytes.
     * @param si True to use metric (SI) units, aka powers of 1000. False to use
     *           binary (IEC), aka powers of 1024.
     * @param dp Number of decimal places to display.
     *
     * @return Formatted string.
     */
    if (bytes === null) {
        return "";
    }
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** decimals;

    do {
        // eslint-disable-next-line no-param-reassign
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return `${bytes.toFixed(decimals)} ${units[u]}`;
}
