import React, {JSX} from "react";
import {ChatHistoryItem} from "src/types/chat";
import {Spinner} from "src/primitives/spinner";
import {ToolDisplayName} from "src/pages/Home/pages/TablePage/components/TableChatArea/titles";

interface LastItemProps {
  historyItem: ChatHistoryItem;
}

export function LastItem({historyItem}: LastItemProps): JSX.Element | null {
    // The most recent item.
    // This is primarily to display loading indicator for tool call.

    if (!historyItem.toolCalls || historyItem.toolCalls.length === 0) {
        return null;
    }

    return (
        <div
            className="p-4 rounded-md bg-black/80 max-w-[75%] text-white fadeIn"
        >
            <div>
                <ul className="monospace text-xs">
                    {historyItem.toolCalls.map((toolCall) => (
                        <li
                            key={`indicator-${toolCall.id}`}
                            className="flex items-center gap-4"
                        >
                            <Spinner size="sm" theme="lime"/>
                            <span>
                                {ToolDisplayName[toolCall.function.name]}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
