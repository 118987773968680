import React, {JSX} from "react";
import {useGetChart} from "src/api/charts/useGetChart";
import {ChartRender} from "src/charts/ChartRender";
import {useTableData} from "src/api/data/useGetTableData";
import {formatDataFromQueryResult} from "src/utils/tableData";
import {ErrorComponent} from "src/components/ErrorComponent";


interface GridItemChartProps {
    chartId: string;
    tableId: string;
}


export function GridItemChart({chartId, tableId}: GridItemChartProps): JSX.Element | null {
    const {chart, loading} = useGetChart(chartId);
    // @ts-ignore
    const {result, loading: loadingData} = useTableData(tableId, {limit: 1000});
    const isLoading = loading || loadingData;

    if (!chart) {
        return null;
    }

    return (
        <ErrorComponent
            fallback={(
                <div>
                    Failed to load chart
                </div>
            )}
        >
            <ChartRender
                data={formatDataFromQueryResult(result?.columns, result?.rows)}
                config={chart?.config}
                series={chart?.series}
                loading={isLoading}
            />
        </ErrorComponent>
    );
}
