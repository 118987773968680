import {ToolCall} from "src/types/chat";
import {useCallback, useRef} from "react";


type ProcessToolCallsOptions = [
    proccesstoolCalls: (toolCalls: ToolCall[]) => Promise<void>,
    processedToolCalls: string[]
]

export function useProcessToolCalls(tableId: string): ProcessToolCallsOptions {
    const processedToolCalls = useRef<string[]>([]);

    const processToolCalls = useCallback(async (toolCalls: ToolCall[]) => {
        toolCalls.forEach((toolCall) => {
            if (processedToolCalls.current.indexOf(toolCall.id) > -1) {
                // already processed
                return;
            }

            // console.log("Processing tool call: ", toolCall, tableId);
            // process and add to processed list
            processedToolCalls.current.push(toolCall.id);
        });
    }, []);

    return [processToolCalls, processedToolCalls.current];
}
