import {ChartConfig} from "src/types/chart";
import {AXIS_TYPES, CHART_TYPES} from "src/components/ChartBuilder/constants";


export function getDefaultConfig(): ChartConfig {
    return {
        chartType: CHART_TYPES.SCATTER,
        titleText: "",
        yAxisLabel: "",
        // @ts-ignore
        yAxisType: AXIS_TYPES.VALUE,
        // X-axis!
        xAxis: "",
        xAxisLabel: "",
        // @ts-ignore
        xAxisType: AXIS_TYPES.VALUE,
        // Other
        aggregateSeries: false
    };
}
