import {useCallback} from "react";
import {useGetDashboard} from "src/api/dashboard/useGetDashboard";
import {useUpdateDashboard} from "src/api/dashboard/useUpdateDashboard";
import {LayoutItem} from "src/types/dashboard";
import {formatLayoutItems} from "./utils";


type UseRemoveDashboardItemType = [
    (itemId: string) => Promise<void>,
    {
        loading: boolean
    }
];

export function useRemoveDashboardItem(tableId: string): UseRemoveDashboardItemType {
    const {data} = useGetDashboard(tableId);
    const [updateDashboard, {loading}] = useUpdateDashboard(tableId);

    const func = useCallback(async (itemId: string) => {
        const layoutItems = formatLayoutItems(data?.layout);
        const updatedItems = layoutItems.filter((item: LayoutItem) => item.i !== itemId);
        const result = await updateDashboard({layout: updatedItems});
        console.log("Removed item from dashboard: ", result.dashboard);
    }, [updateDashboard, data]);

    return [func, {loading}];
}
