import React, {JSX, useMemo, useState} from "react";
import {IconWrapper} from "src/primitives/Icon";
import {FiChevronUp, FiCode, FiDatabase, FiTable} from "react-icons/fi";
import {CopyCode} from "src/primitives/Terminal/CopyCode";
import {extractColumnOrder, formatSQL} from "src/primitives/Terminal/formatting";
import {csvToObjects} from "src/utils/csvData";
import {IconButton} from "src/primitives/button";
import {StructuredQuery} from "./StructuredQuery";
import {ChatDataTable} from "./ChatDataTable";


interface DataFetchingResult {
    sql?: string;
    query?: object;
    resultPreview: string;
}

interface ToolResultDataFetchingProps {
    result: DataFetchingResult;
}

const getTheme = (isActive: boolean): string => {
    if (isActive) {
        return "outline";
    }
    return "black";
};

const ViewTabs = {
    HIDDEN: "hidden",
    DATA: "data",
    SQL: "sql",
    QUERY: "query"
};

export function ToolResultDataFetching({result}: ToolResultDataFetchingProps): JSX.Element {
    const [activeTab, setActiveTab] = useState(ViewTabs.DATA);
    const dataPreview = csvToObjects(result.resultPreview);

    const columnOrder: string[] = useMemo(() => {
        if (result.sql) {
            return extractColumnOrder(result.sql);
        }
        if (result.query) {
            // @ts-ignore
            return result.query.columns;
        }
        return [];
    }, [result]);

    return (
        <div className="p-4 rounded-md bg-black/80 text-white">
            <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        {/* @ts-ignore */}
                        <IconWrapper icon={<FiDatabase/>} size="xs"/>
                        <div>
                            Fetched data
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        {/* @ts-ignore */}
                        <IconButton
                            size="xs"
                            icon={<FiChevronUp/>}
                            onClick={() => setActiveTab(ViewTabs.HIDDEN)}
                            text="Hide"
                            theme={getTheme(activeTab === ViewTabs.HIDDEN)}
                        />
                        {/* @ts-ignore */}
                        <IconButton
                            size="xs"
                            icon={<FiTable/>}
                            onClick={() => setActiveTab(ViewTabs.DATA)}
                            text="Data"
                            theme={getTheme(activeTab === ViewTabs.DATA)}
                        />
                        {result.sql && (
                            // @ts-ignore
                            <IconButton
                                size="xs"
                                icon={<FiCode/>}
                                onClick={() => setActiveTab(ViewTabs.SQL)}
                                text="SQL"
                                theme={getTheme(activeTab === ViewTabs.SQL)}
                            />
                        )}
                        {result.query && (
                            // @ts-ignore
                            <IconButton
                                size="xs"
                                icon={<FiCode/>}
                                onClick={() => setActiveTab(ViewTabs.QUERY)}
                                text="Query"
                                theme={getTheme(activeTab === ViewTabs.QUERY)}
                            />
                        )}
                    </div>
                </div>
                {(activeTab !== ViewTabs.HIDDEN) && (
                    <hr className="border-white/20 mb-2"/>
                )}

                {result.sql && activeTab === ViewTabs.SQL && (
                    <div>
                        {/* @ts-ignore */}
                        <CopyCode
                            language="sql"
                            // @ts-ignore
                            code={formatSQL(result.sql)}
                        />
                    </div>
                )}

                {activeTab === ViewTabs.QUERY && (
                    <div>
                        {/* @ts-ignore */}
                        <StructuredQuery query={result.query}/>
                    </div>
                )}

                {activeTab === ViewTabs.DATA && (
                    <div>
                        {/* @ts-ignore */}
                        <ChatDataTable
                            data={dataPreview}
                            columnOrder={columnOrder}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
