import React, {JSX} from "react";
import {ChatHistoryItem, ToolCall} from "src/types/chat";
import {arrayToMap} from "src/utils/misc";
import {tryParseJson} from "src/utils/formatting";
import {ErrorComponent} from "src/components/ErrorComponent";
import {ChatItem} from "./components/ChatHistoryItem";
import {LastItem} from "./components/ChatHistoryItem/LastItem";
import {useProcessToolCalls} from "./useProcessToolCalls";


interface ChatHistoryProps {
    tableId: string;
    historyItems: ChatHistoryItem[];
}


function extractToolCalls(historyItems: ChatHistoryItem[]): ToolCall[] {
    try {
        return historyItems.flatMap((item) => {
            if (item.toolCalls) {
                return item.toolCalls;
            }
            return [];
        });
    } catch (e) {
        console.error("Failed to extract tool calls", e);
        console.log("Received history items: ", historyItems);
        return [];
    }
}

export function ChatHistory({historyItems, tableId}: ChatHistoryProps): JSX.Element {
    const [processToolCalls] = useProcessToolCalls(tableId);

    const allToolCalls = extractToolCalls(historyItems);
    processToolCalls(allToolCalls);

    const allToolCallsMap = arrayToMap(allToolCalls, "id");
    const toolCallResults = historyItems.filter((item) => item.role === "tool");
    const toolCallsResultMap = toolCallResults.reduce((acc, item) => {
        // @ts-ignore
        return Object.assign({}, acc, {[item.toolCallId]: tryParseJson(item.content)});
    }, {});
    const lastItem = historyItems.length > 0 ? historyItems[historyItems.length - 1] : null;

    return (
        <div className="mb-8 fadeIn">
            <div className="flex flex-col gap-8">
                {historyItems.map((item: ChatHistoryItem, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>
                        <ErrorComponent
                            fallback={(
                                <div className="border-red-500 bg-red-100 p-4 rounded-md">
                                    <div>Failed to render chat item</div>
                                    <code>{JSON.stringify(item)}</code>
                                </div>
                            )}
                        >
                            <ChatItem
                                item={item}
                                toolCallsMap={allToolCallsMap}
                                toolCallsResultMap={toolCallsResultMap}
                            />
                        </ErrorComponent>
                    </div>
                ))}
                {lastItem && (
                    <LastItem
                        historyItem={lastItem}
                    />
                )}
            </div>
        </div>
    );
}
