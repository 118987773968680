import ecStat from "echarts-stat";
import {selectColumns, selectColumnsFlat, uniquePairs} from "src/utils/misc.js";
import {nanoId} from "src/utils/id.js";
import {sortByValue} from "src/utils/sorting.js";
import {formatPercent} from "src/utils/formatting.js";
import {CHART_COLORS} from "src/components/ChartBuilder/colors.js";
import {isNumber} from "src/utils/validators.js";
import {ChartConfig, ChartData, ChartSeries} from "src/types/chart";
import {calculateRegressionResult} from "./regressionStats.js";
import {aggregateData} from "./data.js";
import {getAxisLabelFontStyle, getAxisTitleFontStyle, getDefaultTextStyle} from "./utils.js";
import {renderTooltip} from "./renders/tooltip.js";


export function filterValidSeries(series: ChartSeries[]) {
    return series.filter((seriesItem) => {
        return seriesItem.name;
    });
}


export function formatOptions(data: ChartData, chartConfig: ChartConfig, series: ChartSeries[]): object {
    const {xAxis, aggregateSeries} = chartConfig;

    const chartSeries = series.map((seriesConfig) => {
        const yEncoding = aggregateSeries ? `${seriesConfig.column}__${seriesConfig.aggregation}` : seriesConfig.column;

        return {
            id: seriesConfig.id,
            name: seriesConfig.name,
            datasetIndex: 0,
            type: chartConfig.chartType,
            encode: {
                x: xAxis,
                y: yEncoding
            },
            // coordinateSystem: "cartesian2d",
            // xAxisIndex: 0,
            yAxisIndex: seriesConfig.yAxis === "right" ? 1 : 0,
            label: {
                show: false,
                position: "top"
            },
            lineStyle: {
                // https://echarts.apache.org/en/option.html#series-line.lineStyle
                width: 3
                // color: "#444"
            },
            // Regression type option
            regressionType: seriesConfig.regressionType
        };
    });

    const regressionIds = new Set();
    const regressionSeries = chartSeries.filter((item) => !!item.regressionType).map((item) => {
        if (data.length === 0) {
            // most likely data not loaded
            return {};
        }
        const {regressionType} = item;
        const regressionCol = item.encode.y;
        // @ts-ignore
        const regressionData = selectColumnsFlat(data, [xAxis, regressionCol]).filter(([x, y]) => {
            return isNumber(x) && isNumber(y);
        });

        if (regressionData.length === 0) {
            // No valid data
            console.log("No valid regression data for series: ", item);
            console.log("Given data: ");
            // console.table(data);
            return {};
        }

        // @ts-ignore
        const regression = ecStat.regression(regressionType, regressionData, {});
        // @ts-ignore
        const regressionResult = calculateRegressionResult(regressionData, regressionType, regression.parameter);

        // Make sure we get unique IDs even for duplicated series
        let regressionSeriesId = `regression_${regressionCol}`;
        if (regressionIds.has(regressionSeriesId)) {
            regressionSeriesId += `_${nanoId(6)}`;
        }
        regressionIds.add(regressionSeriesId);

        const color = "#444";

        return {
            id: regressionSeriesId,
            name: `${regression.expression} (R2: ${formatPercent(regressionResult.r2)})`,
            type: "line",
            yAxisIndex: item.yAxisIndex,
            label: {
                show: false,
                position: "top"
            },
            // regression returns multiple combinations,
            // but it's generally enough to display the unique pairs.
            data: uniquePairs(regression.points),
            color,
            encode: {x: 0, y: 1},
            lineStyle: {
                color,
                width: 2
            },
            showSymbol: false,
            regressionResult
        };
    });

    // Avoid invalid series
    // @ts-ignore
    const allSeries = chartSeries.concat(regressionSeries).filter(({id}) => !!id);

    const showY2 = allSeries.filter(({yAxisIndex}) => yAxisIndex === 1).length > 0;

    const legends = allSeries.map((item) => ({
        id: item.id,
        name: item.name
    }));

    return {
        textStyle: getDefaultTextStyle(),
        color: CHART_COLORS,
        title: {
            text: chartConfig.titleText,
            left: "center",
            top: "0px",
            textStyle: {
                fontSize: 18
            },
            subtextStyle: {
                fontSize: 16
            }
        },
        tooltip: {
            // @ts-ignore
            formatter(params) {
                return renderTooltip(params);
            }
        },
        legend: {
            // https://echarts.apache.org/en/option.html#legend
            show: true,
            top: 30,
            right: 0,
            // bottom: 0,
            // left: "center",
            data: legends,
            textStyle: {
                // width: 100,
                fontSize: 11,
                overflow: "truncate"
            },
            itemGap: 16,
            itemWidth: 18,
            itemHeight: 12
        },
        grid: {
            containLabel: true,
            top: "64px"
            // left: "5%",
            // right: "5%",
            // bottom: "10%"
        },
        xAxis: {
            scale: true,
            name: chartConfig.xAxisLabel,
            nameLocation: "center",
            nameGap: 40,
            nameTextStyle: getAxisTitleFontStyle(),
            type: chartConfig.xAxisType,
            axisLabel: {
                ...getAxisLabelFontStyle()
            }
        },
        yAxis: [{
            id: "y1",
            scale: false,
            position: "left",
            name: chartConfig.yAxisLabel,
            nameLocation: "center",
            nameGap: 75,
            nameTextStyle: getAxisTitleFontStyle(),
            type: chartConfig.yAxisType,
            axisLabel: {
                ...getAxisLabelFontStyle()
            }
        }, {
            id: "y2",
            scale: true,
            position: "right",
            show: showY2,
            type: chartConfig.yAxisType,
            axisLabel: {
                ...getAxisLabelFontStyle()
            }
        }],
        dataset: [{
            source: data
        }],
        series: allSeries
        /* Default config
        series: [
            {
                datasetIndex: 0,
                type: chartConfig.chartType,
                encode: {
                    x: xAxis,
                    y: "sold_price"
                }
            }
        ]
         */
    };
}


export function buildOptions(data: ChartData, chartConfig: ChartConfig, series: ChartSeries[]): object {
    const filterRelevantData = false;

    const {xAxis} = chartConfig;
    const validSeries = filterValidSeries(series);

    if (chartConfig.aggregateSeries) {
        const aggregatedData = aggregateData(data, xAxis, validSeries);
        const sortedData = sortByValue(aggregatedData, xAxis);

        return formatOptions(sortedData, chartConfig, validSeries);
    }

    const colNames = [xAxis, ...series.map(({column}) => column)];
    const relevantData = filterRelevantData ? selectColumns(data, colNames) : data;

    return formatOptions(relevantData, chartConfig, validSeries);
}
