import React, {JSX} from "react";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {EditChartPage} from "./pages/EditChartPage/index.jsx";
import {TableDashboard} from "./pages/TableDashboard/index";


interface TableDashboardProps {
    tableId: string;
}

function RenderChartPage({tableId}: TableDashboardProps): JSX.Element {
    const {chartId} = useParams();

    if (!chartId) {
        return <Navigate to="" />;
    }

    return (
        <EditChartPage
            tableId={tableId}
            chartId={chartId}
        />
    );
}


export function TableDashboardPage({tableId}: TableDashboardProps): JSX.Element {
    return (
        <Routes>
            <Route
                path=""
                element={<TableDashboard tableId={tableId}/>}
            />
            <Route
                path="charts/:chartId"
                element={<RenderChartPage tableId={tableId}/>}
            />
            <Route
                path="*"
                element={<Navigate to="" />}
            />
        </Routes>
    );
}
