import React from "react";
import {MarkdownContent} from "src/primitives/Markdown";
import {PageMeta} from "src/pages/Web/components/PageMeta";
import {LandingPageWrapper} from "src/pages/Web/pages/LandingPages/LandingPageWrapper";
import {useMarkdownContent} from "src/primitives/Markdown/useMarkdownContent";


export function SavePandasDataframePage() {
    const {content, isLoading} = useMarkdownContent(() => {
        // @ts-ignore
        return import("./content.md?raw");
    });

    if (isLoading) {
        return null;
    }

    return (
        <>
            <PageMeta
                title="Saving pandas dataframes"
                description={`
                How to save pandas dataframes efficiently.
                `}
            />
            <LandingPageWrapper>
                <MarkdownContent content={content}/>
            </LandingPageWrapper>
        </>
    );
}
