import {useCallback, useMemo} from "react";
import {gql, useMutation} from "@apollo/client";
import {CHART_FIELDS} from "src/api/fragments.js";
import {GET_CHARTS_QUERY} from "./useGetCharts";


const MUTATION = gql`
    ${CHART_FIELDS}
    mutation CreateChart($data: CreateChartInput!) {
        createChart (data: $data) {
            ok
            errorCode
            chart {
                ... ChartFields
            }
        }
    }
`;


function updateCache(tableId, cache, response) {
    const {ok, chart} = response.data.createChart;
    if (!ok) {
        return;
    }
    const data = cache.readQuery({query: GET_CHARTS_QUERY, variables: {tableId}});
    const updatedCharts = [...data.charts, chart];
    cache.writeQuery({
        query: GET_CHARTS_QUERY,
        variables: {tableId},
        data: {
            charts: updatedCharts
        }
    });
}

export function useCreateChart(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        return mutation({
            variables: {data: {tableId}},
            update(cache, response) {
                updateCache(tableId, cache, response);
            }
        }).then((response) => {
            return response.data.createChart;
        });
    }, [mutation, tableId]);

    const result = useMemo(() => {
        return data?.createChart;
    }, [data]);

    return [doMutate, {loading, error, result}];
}
