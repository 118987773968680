import React, {JSX, useCallback} from "react";
import {useEditChart} from "src/pages/Home/pages/TablePage/pages/TableDashboardPage/utils";
import {isChartId} from "../utils";
import {useUpdateItemId} from "../useUpdateItemId";
import {ChartModal} from "./ChartModal";
import {useRemoveDashboardItem} from "../useRemoveDashboardItem";
import {GridItemChart} from "./GridItemChart";
import {GridItemHeader} from "./GridItemHeader";
import {CreateChart} from "./CreateChart";


interface GridItemProps {
    id: string;
    tableId: string;
}


export function GridItem({id, tableId}: GridItemProps): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);
    const [updateId] = useUpdateItemId(tableId);
    const [removeItem] = useRemoveDashboardItem(tableId);
    const [editChart] = useEditChart();
    const onSelectChart = useCallback(async (chartId: string): Promise<void> => {
        await updateId(id, chartId);
    }, [updateId, id]);

    const isChart = isChartId(id);

    return (
        <>
            <div className="h-full flex flex-col gap-2">
                <GridItemHeader
                    id={id}
                    setIsOpen={setIsOpen}
                    removeItem={removeItem}
                    editChart={editChart}
                />

                {isChart && (
                    <div>
                        <GridItemChart
                            chartId={id}
                            tableId={tableId}
                        />
                    </div>
                )}
                {!isChart && (
                    <div className="flex-grow flex-center">
                        <CreateChart
                            tableId={tableId}
                            itemId={id}
                        />
                    </div>
                )}
            </div>

            <ChartModal
                tableId={tableId}
                isOpen={isOpen}
                close={() => setIsOpen(false)}
                onSelectChart={onSelectChart}
            />
        </>
    );
}
