import React, {JSX} from "react";
import {FiEdit, FiSettings, FiTrash} from "react-icons/fi";
import {IconWrapper} from "src/primitives/Icon";


export interface GridItemHeaderProps {
    id: string;
    editChart: (id: string) => void;
    removeItem: (id: string) => void;
    setIsOpen: (isOpen: boolean) => void;
}


export function GridItemHeader({
    id,
    editChart,
    removeItem,
    setIsOpen
}: GridItemHeaderProps): JSX.Element {
    return (
        <div className="flex items-center justify-between">
            <div className="text-xs monospace text-neutral-500">
                {id}
            </div>
            <div className="flex items-center gap-2">
                <button
                    type="button"
                    onClick={() => editChart(id)}
                    className=""
                >
                    {/* @ts-ignore */}
                    <IconWrapper icon={<FiEdit/>} size="xs" clickable={true}/>
                </button>
                <button
                    type="button"
                    onClick={() => setIsOpen(true)}
                    className=""
                >
                    {/* @ts-ignore */}
                    <IconWrapper icon={<FiSettings/>} size="xs" clickable={true}/>
                </button>
                <button
                    type="button"
                    onClick={() => removeItem(id)}
                    className="text-red-600"
                >
                    {/* @ts-ignore */}
                    <IconWrapper icon={<FiTrash/>} size="xs" clickable={true}/>
                </button>
            </div>
        </div>
    );
}
