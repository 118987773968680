import React, {JSX, useMemo} from "react";
import {DialogModal} from "src/primitives/modals/Dialog";
import {useGetChart} from "src/api/charts/useGetChart";
import {useTableData} from "src/api/data/useGetTableData";
import {ChartBuilder} from "src/components/ChartBuilder";
import {useGetColumns} from "src/api/tableColumns/useGetColumns";
import {formatDataFromQueryResult} from "src/utils/tableData";
import {UpdateChartOptions} from "./UpdateChartOptions";


interface EditChartModalProps {
    tableId: string;
    chartId: string | null;
    isOpen: boolean;
    close: () => void;
}


export function EditChartModal({
    tableId,
    chartId,
    isOpen,
    close
}: EditChartModalProps): JSX.Element {
    const {chart, loading} = useGetChart(chartId);
    const [latestOptions, setLatestOptions] = React.useState<object | null>(null);
    const [latestTitle, setLatestTitle] = React.useState<string | null>(null);

    // @ts-ignore
    const {result, loading: loadingData} = useTableData(tableId, {limit: 1000});
    const {columns} = useGetColumns(tableId);
    const isLoading = loading || loadingData;

    const data = useMemo(() => {
        if (result?.columns && result?.rows) {
            return formatDataFromQueryResult(result.columns, result.rows);
        }
        return [];
    }, [result]);

    return (
        <DialogModal
            title={(
                <div>Edit chart</div>
            )}
            footer={(
                <div>
                    {chart && (
                        <UpdateChartOptions
                            chart={chart}
                            latestOptions={latestOptions}
                            latestTitle={latestTitle}
                        />
                    )}
                </div>
            )}
            isOpen={isOpen}
            close={close}
            size="full"
            verticalAlign="top"
        >
            <div className="w-[80vw]">
                {isLoading ? "Loading..." : null}
                {chart && (
                    <div>
                        <ChartBuilder
                            columns={columns}
                            data={data}
                            chartTitle={chart.title}
                            updateChartTitle={setLatestTitle}
                            onChange={setLatestOptions}
                            initialConfig={chart.config}
                            initialSeries={chart.series}
                        />
                    </div>
                )}
            </div>
        </DialogModal>
    );
}
