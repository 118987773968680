export const smoothScrollToBottom = (element: HTMLElement, duration = 300) => {
    // Get the target scroll position
    const targetPosition = element.scrollHeight - element.clientHeight;
    const startPosition = element.scrollTop;
    const distance = targetPosition - startPosition;

    let startTime: number | null = null;

    // Easing function for smooth animation
    const easeOutCubic = (t: number) => 1 - (1 - t) ** 3;

    // Animation function
    const animation = (currentTime: number) => {
        if (!startTime) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);

        // Calculate the new scroll position using easing
        const easeProgress = easeOutCubic(progress);
        // eslint-disable-next-line no-param-reassign
        element.scrollTop = startPosition + distance * easeProgress;

        // Continue animation if not complete
        if (timeElapsed < duration) {
            requestAnimationFrame(animation);
        }
    };

    // Start the animation
    requestAnimationFrame(animation);
};
