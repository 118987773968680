import {useCallback} from "react";
import {useAddSearchParam, useRemoveSearchParam} from "src/utils/urlParams";


type UseEditChartType = [
    (id: string) => void,
    () => void
];


export function useEditChart() : UseEditChartType {
    const addSearchParam = useAddSearchParam();
    const removeSearchParam = useRemoveSearchParam();

    const editChart = useCallback((chartId: string) => {
        addSearchParam("chartId", chartId);
    }, [addSearchParam]);

    const cancelEditChart = useCallback(() => {
        removeSearchParam("chartId");
    }, [removeSearchParam]);

    return [editChart, cancelEditChart];
}
