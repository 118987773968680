import React, {JSX} from "react";

interface SortOption {
    name: string;
    desc: boolean;
}

interface FilterOption {
    name: string;
    op: string;
    value: string;
}

interface StructuredQueryItem {
    columns: string[];
    limit: number;
    sort_options?: SortOption[];
    filter_options?: FilterOption[];
}

export interface StructuredQueryProps {
    query: StructuredQueryItem;
}


export function StructuredQuery({query}: StructuredQueryProps): JSX.Element {
    return (
        <div className="monospace text-sm">
            <ul>
                <li className="mb-2">
                    Columns:
                    <ul className="ml-4 list-disc">
                        {query.columns.map((column) => (
                            <li key={column}>{column}</li>
                        ))}
                    </ul>
                </li>
                {query.sort_options && (
                    <li className="mb-2">
                        Sort:
                        <ul className="ml-4 list-disc">
                            {query.sort_options.map((sortOption) => (
                                <li key={sortOption.name}>
                                    {sortOption.name}: {sortOption.desc ? "desc" : "asc"}
                                </li>
                            ))}
                        </ul>
                    </li>
                )}
                {query.filter_options && (
                    <li className="mb-2">
                        Filters:
                        <ul className="ml-4 list-disc">
                            {query.filter_options.map((filterOption) => (
                                <li key={filterOption.name}>
                                    {filterOption.name} {filterOption.op} {filterOption.value}
                                </li>
                            ))}
                        </ul>
                    </li>
                )}
                <li className="mb-2">
                    Limit: {query.limit}
                </li>
            </ul>
        </div>
    );
}
