import React, {JSX} from "react";
import {ChatHistoryItem, ToolCall} from "src/types/chat";
import {AssistantMessage} from "./AssistantMessage";
import {UserMessage} from "./UserMessage";
import {ToolMessage} from "./ToolMessage";


interface ChatItemProps {
    item: ChatHistoryItem;
    toolCallsMap: Record<string, ToolCall>;
    toolCallsResultMap: Record<string, object>;
}


export function ChatItem({item, toolCallsMap, toolCallsResultMap}: ChatItemProps): JSX.Element {
    if (item.role === "user") {
        return <UserMessage content={item.content}/>;
    }

    if (item.role === "assistant") {
        return <AssistantMessage content={item.content} toolCalls={item.toolCalls}/>;
    }

    if (item.role === "tool" && item.toolCallId) {
        const toolCall = toolCallsMap[item.toolCallId];

        return (
            <ToolMessage
                content={item.content}
                toolCall={toolCall}
                toolCallResults={toolCallsResultMap}
            />
        );
    }

    return (
        <div>{JSON.stringify(item)}</div>
    );
}
