import React, {JSX} from "react";

interface UserMessageProps {
  content: string;
}

export function UserMessage({content}: UserMessageProps): JSX.Element {
    return (
        <div className="p-4 rounded-md bg-lime-200/70 ml-8">
            {content}
        </div>
    );
}
